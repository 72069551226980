import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import SendIcon from '@mui/icons-material/Send';

export function CustomizedInputBase(props) {
  const keyDown = (e) => {
    //console.log(`Pressed keyCode ${e.key}`);
    if (e.key === 'Enter') {
        props.sendMessage();
        e.preventDefault();
    }
  }
  return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Message"
        inputProps={{ 'aria-label': 'Message' }}
        inputRef = {props.inputRef}
        onKeyDown = {keyDown}
      />
      {/*
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      */}
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="send" onClick={props.sendMessage}>
        <SendIcon />
      </IconButton>
    </Paper>
  );
}