import React, { useContext, useEffect, useState } from "react";
import { useParams, Outlet } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { AuthContext, FireactContext } from "../core";
import { Alert, Box, Container } from "@mui/material";
import { checkPermission } from "./utilities";

export const SubscriptionContext = React.createContext();

export const SubscriptionProvider = (props) => {
    const {loader, enableExtensionMenu, ...rest} = props;
    const { subscriptionId } = useParams();
    const [ subscription, setSubscription ] = useState(null);
    const { authInstance, firestoreInstance } = useContext(AuthContext);
    const [ error, setError ] = useState(null);
    const { config } = useContext(FireactContext);
    
    useEffect(() => {
        setError(null);
        const defaultPermissions = [];
        const adminPermissions = [];
        for(let permission in config.saas.permissions){
            if(config.saas.permissions[permission].default){
                defaultPermissions.push(permission);
            }
            if(config.saas.permissions[permission].admin){
                adminPermissions.push(permission);
            }
        }
        //console.log("defaultPermissions ", defaultPermissions, "adminPermissions ", adminPermissions);
        const docRef = doc(firestoreInstance, "subscriptions", subscriptionId);

        getDoc(docRef).then(docSnap => {
            if(docSnap.exists()){
                const sub = docSnap.data();
                sub.id = subscriptionId;
                setSubscription(sub);
                if (authInstance.currentUser) {
                    const permission = checkPermission(sub, authInstance.currentUser.uid, adminPermissions);
                    //console.log("admin permission ", permission);
                    if (permission) {
                        enableExtensionMenu("groupAdmin");
                    }

                    const accessPermission = checkPermission(sub, authInstance.currentUser.uid, defaultPermissions);
                    //console.log("access permission ", accessPermission);
                    if (accessPermission) {
                        enableExtensionMenu("groupAccess");
                    }
                }
            }else{
                // no subscription
                setError("No "+config.saas.subscription.singular+" matches the ID");
            }
        }).catch(error => {
            setError(error.message);
        });
    }, [authInstance, subscriptionId, firestoreInstance, config.saas.subscription.singular, setError]);

    return (
        <>
            {error !== null?(
                <Box mt={10}>
                    <Container maxWidth="sm">
                        <Box component="span" m={5} textAlign="center">
                            <Alert severity="error" >{error}</Alert>
                        </Box>
                    </Container>
                </Box>
            ):(
                <>
                    {subscription !== null?(
                        <SubscriptionContext.Provider value={{subscription, setSubscription}}>
                            <Outlet />
                        </SubscriptionContext.Provider>
                    ):(
                        <Box mt={10}>
                            <Container maxWidth="sm">
                                <Box component="span" m={5} textAlign="center">
                                    {loader}
                                </Box>
                            </Container>
                        </Box>
                    )}
                </>
            )}
            
        </>
    )

}
