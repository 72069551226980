import React, { useContext, useEffect, useState, useCallback } from "react";
import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled } from "@mui/material";
import {PageSection} from "../template/TemplateElements";
import {APIProvider, Map, Marker, useMapsLibrary, useMap, createMarker} from '@vis.gl/react-google-maps';
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, updateDoc, orderBy, startAt, endAt, documentId } from 'firebase/firestore';
import * as geofire from 'geofire-common';
import InfoDialog from "./InfoDialog";
import ChatDialog from "./ChatDialog";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ChatIcon from '@mui/icons-material/Chat';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Stack from '@mui/material/Stack';
import ProductCard from "./ProductCard";
import Link from '@mui/material/Link';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { green, cyan } from '@mui/material/colors';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from "react-router-dom";
import Rating from '@mui/material/Rating';

const Matching = ({position}) => {
    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const { authUser } = useContext(AuthContext);
    const [matchingResults, setMatchingResults] = useState([]); 
    const [matchingTreatments, setMatchingTreatments] = useState([]); 
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [openChatDialog, setOpenChatDialog] = useState(false);
    const [dialogInfo, setDialogInfo] = useState(null);
    const navigate = useNavigate();

    const getNearbyPlaces = useCallback(async (position) => {
        //console.log("position", position);
        setMatchingResults([]); //start a new search
        // LA 34.054908,-118.242643
        //const center = [34.054908, -118.242643];
        const center = [position.lat, position.lng];
        const radiusInM = 50 * 1000; //50km

        // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
        // a separate query for each pair. There can be up to 9 pairs of bounds
        // depending on overlap, but in most cases there are 4.
        const bounds = geofire.geohashQueryBounds(center, radiusInM);
        const promises = [];
        for (const b of bounds) {
            const q = query(
                collection(firestoreInstance, 'places'), 
                orderBy('geohash'), 
                startAt(b[0]), 
                endAt(b[1]));

            promises.push(getDocs(q));
        }
        //console.log('promises ', promises);

        // Collect all the query results together into a single list
        const snapshots = await Promise.all(promises);

        const matchingDocs = [];
        for (const snap of snapshots) {
            for (const doc of snap.docs) {
                const lat = doc.get('lat');
                const lng = doc.get('lng');

                // We have to filter out a few false positives due to GeoHash
                // accuracy, but most will match
                const distanceInKm = geofire.distanceBetween([lat, lng], center);
                const distanceInM = distanceInKm * 1000;
                if (distanceInM <= radiusInM) {
                matchingDocs.push(doc);
                }
            }
        }

        //console.log('matchingDocs', matchingDocs);

        const mResults = [];
        const matchedUsers = [];
        
        matchingDocs && matchingDocs
        .filter(matchingDoc => (Array.isArray(matchingDoc.data().users) && matchingDoc.data().users.length))
        .map((matchingDoc, index) => {
            const users = matchingDoc.data().users;
            for (const user of users) {
                matchedUsers.push({user_id:user, address: matchingDoc.data().address});
            }
        });
        /*
        
        //console.log('matchedUsers', matchedUsers);
        for (const user of matchedUsers) {
            const userRef = doc(firestoreInstance, "users", user.user_id);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
                user.user = userSnap.data();
                if (user.user.userType === 'Nurse') {
                    mResults.push(user);
                }
            }
        }
        console.log('mResults', mResults);
        setMatchingResults(mResults);

        */

        //console.log('matchedUsers', matchedUsers);
        for (const user of matchedUsers) {
            //--- nurse profile common data ---
            const userRef = doc(firestoreInstance, "users", user.user_id);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
                user.user = userSnap.data();
                if (user.user.userType === 'Nurse') {
                    
                    //get existing user service account
                    const docRef = doc(firestoreInstance, "userServiceAccounts", user.user_id);
                    await getDoc(docRef).then(docSnap => {
                        if (docSnap.exists()) {
                            const data = docSnap.data();
                            //console.log("service account data:", data);
                            user.serviceAccount = data;
                        }
                    }).catch(err => {
                        //
                    });

                    //add treatment services
                    const servicesRef = collection(firestoreInstance, '/services');
                    const serviceQuery = query(servicesRef, where('user_id', '==', user.user_id));
                    
                    await getDocs(serviceQuery).then(async serviceSnapshot => {
                        const productIds = [];
                        serviceSnapshot.forEach(record => {
                            productIds.push(record.data().product_id);
                        });
                        //get the products
                        const treatmentsRef = collection(firestoreInstance, '/treatments');
                        const treatmentQuery = query(treatmentsRef, where(documentId(), 'in', productIds));

                        await getDocs(treatmentQuery).then(treatmentSnapshot => {
                            const products = [];
                            treatmentSnapshot.forEach(record => {
                                const product = record.data();
                                product.product_id = record.id;
                                products.push(product);
                            });
                            user.services = products;
                            //console.log('user', user);
                            mResults.push(user);
                            //console.log('user', user);
                            //setMatchingResults(mResults);
                            //console.log('mResults', mResults);
                        }).catch(err => {});
                    }).catch(err => {});
                    
                    ///-----
                    /*
                    Promise.all([getDocs(serviceQuery)]).then(([serviceSnapshot]) => {
                        const productIds = [];
                        serviceSnapshot.forEach(record => {
                            productIds.push(record.data().product_id);
                        });
                        //get the products
                        const treatmentsRef = collection(firestoreInstance, '/treatments');
                        const treatmentQuery = query(treatmentsRef, where(documentId(), 'in', productIds));

                        Promise.all([getDocs(treatmentQuery)]).then(([treatmentSnapshot]) => {
                            const products = [];
                            treatmentSnapshot.forEach(record => {
                                products.push(record.data());
                            });
                            user.services = products;
                            //console.log('user', user);
                            mResults.push(user);
                            //console.log('user', user);
                            //setMatchingResults(mResults);
                            console.log('mResults0', mResults);
                        }).catch(error => {
                            //
                        });
                    }).catch(error => {
                        //
                    });  
                    */          
                }
            }
            //--- nurse profile common data ---
        }
        //console.log('mResults1', mResults);
        setMatchingResults(mResults);
        
        //build matchingTreatments from mResults
        const treatments = [];
        for (const user of mResults) {
            //console.log('user', user);
            for (const service of user.services) {
                /*service.user = user.user;
                service.user_id = user.user_id;
                service.serviceAccount = user.serviceAccount;
                service.address = user.address;*/
                //service.providers = [];
                delete user.services;
                service.provider = user;
                service.providers = [];
                service.providers.push(user);

                const existingService = treatments.find((treatment) => {
                    return treatment.product_id === service.product_id;
                  });

                if (existingService === undefined) {
                    //add more conditions here to see if a service is available, for example, already booked
                    treatments.push(service);
                    //console.log('service', service);
                }
                else {
                    treatments.forEach((treatment, index) => {
                        if (treatment.product_id === existingService.product_id) {
                            treatments[index].providers.push(user);
                        }
                    });
                }
            }
        }
        setMatchingTreatments(treatments);
    }, [position]);

    useEffect(() => {
        getNearbyPlaces(position);
    },[getNearbyPlaces]);

    const showDetails = (item) => {
        //console.log('item', item);
        setOpenInfoDialog(true);
        setDialogInfo(item);
    }

    const startChat = (item) => {
        //console.log('item', item);
        setOpenChatDialog(true);
        setDialogInfo(item);
    }

    const insideAvatarFunction = (e, item) => {
        //if (authUser.userType != 'Nurse') {
            e.stopPropagation();
            //console.log('item', item);
            const slug = item.serviceAccount ? item.serviceAccount.slug : '';
            navigate(config.pathnames.NurseProfile.replace(":nurseSlug", slug));
            //showDetails(item);
            //console.log('Fired insideAvatarFunction()!');
        //}
    }
    
    const everywhereClickFunction = (e, item) => {
        //console.log('Fired everywhereClickFunction()!');
        showDetails(item);
    }

    const handleOpenDialog = (dialogIsOpen) => {
        setOpenInfoDialog(dialogIsOpen);
    }

    const handleOpenChatDialog = (dialogIsOpen) => {
        setOpenChatDialog(dialogIsOpen);
    }

    return (
        <>
        {matchingResults.length > 0 ? (
            <PageSection>
                <Typography variant="h6" align="left" sx={{px: {xs:0, md:2}}} gutterBottom>
                    Available Treatments
                </Typography> 
                <Box sx={{ width: '100%'}}>
                    <Grid container spacing={0}>
                        {matchingTreatments && matchingTreatments.map((service, index) => (
                            <Grid xs={12} md={4} sx={{px: {xs:0, md:2}, py: {xs: 1, md: 1}}}>
                                <ProductCard service={service} showDetails={()=>{showDetails(service)}}/>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </PageSection>  
        ) : (
            <PageSection>
                <Box sx={{px: {xs:0, md:2}}}><LinearProgress color="success"/></Box>
            </PageSection>
        )}
            <InfoDialog openDialog={openInfoDialog} handleOpenDialog={handleOpenDialog} dialogInfo={dialogInfo} />
            {/*<ChatDialog openDialog={openChatDialog} handleOpenDialog={handleOpenChatDialog} dialogInfo={dialogInfo} />*/}
        </>
    )
}

export default Matching;
