import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, CardMedia, CardContent, Container, Grid, Paper, Typography, TextField } from "@mui/material";
import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc, documentId, addDoc, setDoc, arrayUnion, Timestamp, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { useParams, Outlet } from 'react-router-dom';
import InfoDialog from "./InfoDialog";
import ChatDialog from "./ChatDialog";
import ChatIcon from '@mui/icons-material/Chat';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import {PageSection} from "../template/TemplateElements";
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { green } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ProductCard from "./ProductCard";
import SendIcon from '@mui/icons-material/Send';
import Rating from '@mui/material/Rating';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { v4 as uuid, v5 as uuidv5 } from 'uuid';
import ImagesDropzone from "./imagesDropzone";
import ImageElement from "./imageElement";
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs from 'dayjs';
import Link from '@mui/material/Link';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ImageFullScreenDialog from "./ImageFullScreenDialog";
import Footer from "../template/Footer";

const Orders = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [openChatDialog, setOpenChatDialog] = useState(false);
    const [dialogInfo, setDialogInfo] = useState(null);
    const [nurseProfile, setNurseProfile] = useState(null);
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }
        
        setLoaded(true);
        
    },[authInstance, config.saas.permissions, firestoreInstance]);

    const getOrders = useCallback(async () => {
        //get orders
        
    }, [authUser.user]);

    useEffect(() => {
        getOrders();
    },[getOrders]);

    return (
        <>
            {loaded ? (
              <>
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title={`Order Details`} />
                    <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                        <Grid container spacing={0}>
                            <Grid xs={12} md={12}>
                            <PageSection>
                            <Grid container spacing={0}>
                                <Grid xs={12} md={12}>
                                    <Typography component="div" variant="h5" align="center" sx={{pb:1}}>
                                    Your Orders
                                    </Typography>
                                    <Grid container spacing={0}>
                                    
                                        <Grid xs={12} md={12} sx={{px: {xs:0, md:2}, py: {xs: 1, md: 1}}}>
                                        <Card sx={{ width: '100%' }} align="left">
                                            <CardHeader
                                                avatar={
                                                    <CardMedia
                                                    component="img"
                                                    sx={{ maxHeight: 56, maxWidth: 56, bgcolor: green[50], borderRadius: 1, p: '4px', width: 56, height: 56, mr: 1, cursor: 'pointer'}}
                                                    image={`https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/images%2FEnergy_Plus_IV.png?alt=media&token=4d4597e7-47c6-4fd5-9fd2-7cd222e5bede`}
                                                    alt={`image`}
                                                    />
                                                }
                                                title={(<><Typography sx={{cursor: 'pointer'}}>Product Name</Typography></>)}
                                                subheader={
                                                    <>
                                                        <Typography>
                                                        {`date time`}
                                                        </Typography>
                                                        <Typography sx={{fontSize: 11}}>
                                                        ${`200`}
                                                        </Typography>
                                                    </>
                                                }
                                                onClick={()=>{
                                                    const id = 'order-id';
                                                    navigate(config.pathnames.OrderDetails.replace(":orderId", id));
                                                }}
                                            />
                                        </Card>
                                        </Grid>
                            
                                    </Grid>
                                </Grid>
                            </Grid>
                            </PageSection>
                            </Grid>
                        </Grid>
                    </Box>
                    <Footer />
                </Container>
              </>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Orders;