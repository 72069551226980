import React, { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Grid, Typography, Button, IconButton, Box, Stack } from "@mui/material";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';

export default function ImagesDropzone({ setImageList, clearPreviewImages, setClearPreviewImages }) {
   const [previewImages, setPreviewImages] = useState([]);
    
   const onDrop = (acceptedFiles) => {
      //console.log("imagesRef ", imagesRef);
      if (acceptedFiles.length > 0) {
         const file = acceptedFiles[0];
         const imageURL = URL.createObjectURL(file);

         const newImage = {
            file: file,
            URL: imageURL,
         }

         //console.log("local image url", imageURL);
         setPreviewImages((prevState) => [...prevState, newImage]);
         //console.log("local image urls", previewImages);
      }
   };

   const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      onDrop,
      accept: {
         'image/*': ['.jpeg', '.png', '.jpg', '.gif']
      },
      noClick: true,
      noKeyboard: true,
      multiple: false,
   });

   const removeImage = (index) => {
      const newArray = [...previewImages];
      newArray.splice(index, 1);
      setPreviewImages(newArray);
      //console.log('delete image', index);
   }

   useEffect(() => {
      setImageList(previewImages);
   },[previewImages]);

   useEffect(() => {
      if (clearPreviewImages) {
         setPreviewImages([]);
         setClearPreviewImages(false);
      }
   },[clearPreviewImages]);

   return (
      <>
         <Grid container spacing={0}>
            {previewImages.length > 0 && previewImages.map((image, index) => (
               <Grid xs={4} md={4} sx={index % 3 === 1 ? {pl:1, pr:1}: index % 3 === 2 ? {pl:2} : {pr:2}}>
                  <Box sx={{position: 'relative'}}>
                  <img src={image.URL} width="100%" style={{ objectFit: "contain", maxWidth:'100%', maxHeight:'100%'}}/>
                  <IconButton sx={{padding: "2px", position: "absolute", top: 3, right: 3, backgroundColor: grey[300], "&:hover": {backgroundColor: grey[400]}}} color="text.secondary">
                     <DeleteIcon sx={{fontSize: 16}} onClick={()=>{removeImage(index);}}/>
                  </IconButton>
                  </Box>
               </Grid>
            ))}
         </Grid>
         <Box {...getRootProps()} sx={{border: '1px dashed', borderColor: 'text.secondary'}}>
            <input {...getInputProps()} />
            <Grid container direction="column" spacing={2}>
               <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  spacing={1}
               >
                  <Grid item>
                     <IconButton aria-label="add photo" onClick={open}>
                        <AddAPhotoIcon />
                     </IconButton>
                  </Grid>
               </Grid>
            </Grid>
         </Box>
      </>
   );
}
