import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, CardMedia, CardContent, Container, Grid, Paper, Typography, TextField } from "@mui/material";
import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc, documentId, addDoc, setDoc, arrayUnion, Timestamp, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { useParams, Outlet } from 'react-router-dom';
import InfoDialog from "./InfoDialog";
import ChatDialog from "./ChatDialog";
import ChatIcon from '@mui/icons-material/Chat';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import {PageSection} from "../template/TemplateElements";
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { green } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ProductCard from "./ProductCard";
import SendIcon from '@mui/icons-material/Send';
import Rating from '@mui/material/Rating';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { v4 as uuid, v5 as uuidv5 } from 'uuid';
import ImagesDropzone from "./imagesDropzone";
import ImageElement from "./imageElement";
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs from 'dayjs';
import Link from '@mui/material/Link';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ImageFullScreenDialog from "./ImageFullScreenDialog";

const OrderDetails = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const { orderId } = useParams();
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [openChatDialog, setOpenChatDialog] = useState(false);
    const [dialogInfo, setDialogInfo] = useState(null);
    const [nurseProfile, setNurseProfile] = useState(null);
    const [reviews, setReviews] = useState([]);
    
    console.log('orderId ', orderId);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }
        
        setLoaded(true);
        
    },[authInstance, config.saas.permissions, firestoreInstance]);

    const getOrderDetails = useCallback(async (orderId) => {
        //get order
        
    }, [orderId]);

    useEffect(() => {
        getOrderDetails(orderId);
    },[getOrderDetails]);

    return (
        <>
            {loaded ? (
              <>
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title={`Order Details`} />
                    <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                        <Grid container spacing={0}>
                            <Grid xs={12} md={12}>
                            Order Details {orderId}
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
              </>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default OrderDetails;