import React, { useEffect, useState, useContext } from "react";
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Navigate, Outlet } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { doc, getFirestore, setDoc, onSnapshot, getDoc } from "firebase/firestore";
import { FireactContext } from "./Fireact";
import { getFunctions } from "firebase/functions";

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

export const AuthContext = React.createContext();

export const AuthProvider = ({children}) => {

    // authorized user state
    const [authUser, setAuthUser] = useState(
        {
            user: null,
            data: {},
            checked: false
        }
    );

    const [userType, setUserType] = useState(null);

    const { config } = useContext(FireactContext);
    const [firebaseApp, setFirebaseApp] = useState(null);
    const [authInstance, setAuthInstance] = useState(null);
    const [firestoreInstance, setFirestoreInstance] = useState(null);
    const [functionsInstance, setFunctionsInstance] = useState(null);

    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('Customer'); //default user type value
    const handleClose = (newValue) => {
        setOpen(false);
    
        if (newValue) {
          setValue(newValue);
        }

        const userDoc = doc(firestoreInstance, 'users', authUser.user.uid);
        setDoc(userDoc, {
            userType: newValue
        },{merge: true});

        setAuthUser(prevState => ({
            ...prevState,
            userType: newValue
         }));

        // create the unique Group for nurse?
        if (newValue === "Nurse") {
            console.log("new registration, redirect to Dashboard page.");
        }

        //console.log("new user type: ", newValue);
    };

    useEffect(() => {
        const app = initializeApp(config.firebaseConfig);
        const auth = getAuth(app);
        const firestore = getFirestore(app);
        const functions = getFunctions(app);

        setFirebaseApp(app);
        setAuthInstance(auth);
        setFirestoreInstance(firestore);
        setFunctionsInstance(functions);
    
        onAuthStateChanged(auth, (user) => {
            if(user !== null){
                user.getIdToken().then(async token => {
                    const userDoc = doc(firestore, 'users', user.uid);
                    setAuthUser(prevState => ({
                        ...prevState,
                        user: user,
                        checked: true
                     }));

                    await setDoc(userDoc, {
                        displayName: user.displayName,
                        photoURL: user.photoURL,
                        email: user.email
                    },{merge: true}).then(async () => {
                      //handle user type
                      await getDoc(userDoc).then(docSnap => {
                        const data = docSnap.data();
                        //console.log("Document data:", data);
                        // user info from DB
                        if (data.phone) {
                          setAuthUser(prevState => ({
                              ...prevState,
                              phone: data.phone
                          }));
                        }
                        if (data.isAdmin) {
                          setAuthUser(prevState => ({
                              ...prevState,
                              isAdmin: data.isAdmin
                          }));
                        }
                        if (!data.hasOwnProperty("userType")) {
                            setOpen(true);
                            console.log("user type is not set yet");
                        } else {
                            setAuthUser(prevState => ({
                                ...prevState,
                                userType: data.userType
                            }));
                        }
                      }).catch(err => {
                        //
                      });
                    }).catch((error) => {
                      //
                    });
                });
            }else{
                setAuthUser(prevState => ({
                    ...prevState,
                    user: null,
                    checked: true
                 }));
            }
        });
    },[config.firebaseConfig, value]);
    //},[config.firebaseConfig]);// original

    return (
        <AuthContext.Provider value={{
            authUser, setAuthUser, firebaseApp, authInstance, firestoreInstance, functionsInstance
        }}>
            {children}
            {!userType && authUser.user && (
                <ConfirmationDialogRaw
                id="usertype-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                value={value}
                />
            )}
        </AuthContext.Provider>
    )
}

export const AuthRoutes = ({ loader }) => {
    const { authUser } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const signInPath = config.pathnames.SignIn;

    if(authUser.checked){
        if(authUser.user !== null){
            return <Outlet />
        }else{
            return <Navigate to={signInPath+"?re="+document.location.pathname+document.location.search+document.location.hash} />
        }
    }else{
        return (
            <Box mt={10}>
                <Container maxWidth="sm">
                    <Box component="span" m={5} textAlign="center">
                        {loader}
                    </Box>
                </Container>
            </Box>
        )
    }
}

const options = [
  'Customer',
  'Nurse',
];

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Choose My Role</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="usertype"
          name="usertype"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

