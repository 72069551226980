import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { useQuery, gql } from '@apollo/client';
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled } from "@mui/material";
import {PageSection} from "../../template/TemplateElements";

const client = new ApolloClient({
  uri: 'https://flyby-router-demo.herokuapp.com/',
  cache: new InMemoryCache(),
});

const GET_LOCATIONS = gql`
  query GetLocations {
    locations {
      id
      name
      description
      photo
    }
  }
`;

function DisplayLocations() {
  const { loading, error, data } = useQuery(GET_LOCATIONS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return data.locations.map(({ id, name, description, photo }) => (
    <Grid xs={12} md={4}>
      <PageSection>
          <h3>{name}</h3>
          <img width="100%" alt="location-reference" src={`${photo}`} />
           <Typography variant="body1" align="left" gutterBottom>
              {description}
           </Typography>
       </PageSection>
    </Grid>
  ));
}

const Hero = ({loader}) => {
    
    return (
        <>
            <ApolloProvider client={client}>
                <Grid container spacing={0}>
                    <DisplayLocations />
                </Grid>
            </ApolloProvider>
        </>
    )
}

export default Hero;
