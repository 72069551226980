import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { green, cyan } from '@mui/material/colors';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaidIcon from '@mui/icons-material/Paid';
import Button from '@mui/material/Button';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./Stripe/CheckoutForm";
import { httpsCallable } from "firebase/functions";
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, dataValue) {
  return { name, dataValue };
}

export default function ScheduleCard(props) {
  //console.log('props', props.schedule);
  const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
  const [expanded, setExpanded] = React.useState(false);
  const [paid, setPaid] = React.useState(false);
  const [showPayMethod, setShowPayMethod] = React.useState(false);
  const [loadingCC, setLoadingCC] = useState(false);
  const [payButtonDisabled, setPayButtonDisabled] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("You've successfully booked!");
  
  const stripePromise = loadStripe("pk_test_51L44EYAZ8dJdmsUfoGp1AyJ5Mm6jQYpGlUDKUSPdCptoTAyVBmQ03bwhkM0jIh2sgLHy9e65MeQK6WpqAjp736SO00aV0psDqO");
  const [clientSecret, setClientSecret] = useState("");
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };
  
  //console.log('schedule card', props);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const payNow = (amount) => {
    setLoadingCC(true);
    setPayButtonDisabled(true);
    const createCheckoutSession = httpsCallable(functionsInstance, 'fireactjsSaas-createCheckoutSession');
    //console.log('createCheckoutSession ', createCheckoutSession);
    createCheckoutSession({ items: [{ amount: amount }], scheduleId: props.schedule.scheduleId }).then((res) => {
        //console.log('res ', res);
        setClientSecret(res.data.clientSecret)
        setShowPayMethod(true);
        setLoadingCC(false);
    }).catch(error => {
        //setError(error.message);
        //setProcessing(false);
        console.log('error ', error);
    });
  }

  const paymentFormIsReady = () => {
    //scroll
    //console.log('scroll', props.scrollRef.current);
    if (props.scrollRef.current) {
      props.scrollRef.current.scrollTop += 200;
    }
  }

  const paymentCallback = () => {
    setShowPayMethod(false);
    setPaid(true);
    setPayButtonDisabled(false);

    //snackbar
    setSnackbarMessage("Thank you for your payment!");
    setOpen(true);

    //scroll
    //console.log('scroll', props.scrollRef.current);
    if (props.scrollRef.current) {
      props.scrollRef.current.scrollTop = 0;
    }
  }

  const phoneValue = () => {
    return (
      <MuiTelInput sx={{width: '100%'}}
          id="customer-phone-helper-text-aligned"
          value={props.schedule && props.schedule.phone}
          InputProps={{readOnly: true, disableUnderline: true}}
          variant="standard"
          disabled
          color="text.primary"
      />
    );
  }
  const rows = [
    createData('Name', (props.schedule ? props.schedule.fullname : '')),
    createData('Email', (props.schedule ? props.schedule.email : '')),
    createData('Phone', (props.schedule ? phoneValue() : '')),
    createData('Address', (props.schedule ? props.schedule.address : ''))
  ];

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    //scroll
    if (props.scrollRef.current) {
      props.scrollRef.current.scrollTop = 10000;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Card sx={{ width: '100%', bgcolor: cyan[50] }}>
      <CardHeader sx={{bgcolor: green[100]}}
        action={
          <React.Fragment>
            <Typography
                component="div"
                variant="h5"
                color="text.primary"
                align='center'
            >
              ${props.schedule && props.schedule.totalPrice}
            </Typography>
            {paid && (
              <Typography
                  component="div"
                  variant="title2"
                  color="text.primary"
                  align='center'
              >
                Paid
              </Typography>
            )}
          </React.Fragment>
        }
        title={
          <React.Fragment>
            <Typography
                component="div"
                variant="body1"
                color="text.primary"
            >
              <Box sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  mb: 1,
                  }}
              >
                <Avatar sx={{ width: 24, height: 24, fontSize: 12, display: 'inline-flex', mr: 1 }} alt={props.schedule && props.schedule.treatment.provider.serviceAccount.name} src={props.schedule && props.schedule.treatment.provider.user.photoURL} />
                {props.schedule && props.schedule.treatment.provider.serviceAccount.name}
              </Box>
            </Typography>
            <Typography
                component="div"
                variant="subtitle2"
                color="text.primary"
            >
              {props.schedule && props.schedule.time}, {props.schedule && props.schedule.date} 
            </Typography>
          </React.Fragment>
        }
        subheader={
          <React.Fragment>
            <Typography
                component="div"
                variant="body2"
                color="text.primary"
            >
              <MuiTelInput sx={{width: '100%'}}
                  id="service-phone-helper-text-aligned"
                  value={props.schedule && props.schedule.treatment.provider.serviceAccount.phone}
                  InputProps={{readOnly: true, disableUnderline: true}}
                  variant="standard"
                  disabled
                  color="text.primary"
              />
            </Typography>
            <Typography
                component="span"
                variant="body2"
                color="text.primary"
                sx={{pl:1}}
            >
              {props.schedule && props.schedule.treatment.provider.serviceAccount.email}
            </Typography>
          </React.Fragment>
        }
      />
      <CardContent sx={{p:0}}>
        <TableContainer component={Paper} sx={{bgcolor: green[50]}}>
          <Table sx={{ witdh: '100%' }} aria-label="booking detail table">
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row" style={{width: '25%'}}>
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.dataValue}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="body2" color="text.secondary">
        </Typography>
        {/*
        {props.schedule && props.schedule.treatment && (
          <Accordion sx={{bgcolor: cyan[50]}}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls={`panel0-content`}
              id={`panel0-header`}
            >
              <CardMedia
                component="img"
                image={props.schedule.treatment.image}
                alt={props.schedule.treatment.name}
                sx={{ objectFit: "contain", width:24, maxWidth:24, maxHeight:24, pr:1 }}
              />
              <Typography>{props.schedule.treatment.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="caption" color="text.secondary" component="div" align="left">
              { <div dangerouslySetInnerHTML={{ __html: props.schedule.treatment.description }} /> }
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
        */}
      </CardContent>
      <CardActions disableSpacing sx={{bgcolor: green[100],   display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
        {paid ? (
          <>
            <Typography
                component="span"
                variant="body2"
                color="text.secondary"
                sx={{pl:1}}
            >
              Paid 
            </Typography>
          </>
        ) : (
          <>
            <LoadingButton variant="outlined" startIcon={<CreditCardIcon />} onClick={() => {
                payNow(props.schedule.totalPrice);
            }} loading={loadingCC} loadingPosition="start" disabled={payButtonDisabled}>
              Pay Now
            </LoadingButton>
            <Typography
                component="span"
                variant="body2"
                color="text.secondary"
                sx={{pl:1}}
            >
              or 
            </Typography>
            <Button variant="text">Pay On Service</Button>
          </>
        )}
      </CardActions>
      {showPayMethod && (
        <CardContent sx={{p:0}}>
          <Elements options={options} stripe={stripePromise}>
              <CheckoutForm paymentCallback={paymentCallback} paymentFormIsReady={paymentFormIsReady}/>
          </Elements>
        </CardContent>
      )}
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Card>
  );
}