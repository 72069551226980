import * as React from 'react';
import { useContext } from "react";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { AuthContext, FireactContext } from '../lib/core';
import { NavLink } from "react-router-dom";
import { useParams } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import WebIcon from '@mui/icons-material/Web';
import PaymentsIcon from '@mui/icons-material/Payments';
import Divider from '@mui/material/Divider';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

export default function DashboardNestedList(props) {
  const [openManageGroup, setOpenManageGroup] = React.useState(false);
    
  const { subscriptionId } = useParams();
  const { authUser } = useContext(AuthContext);
  const {config} = useContext(FireactContext);
  const pathnames = config.pathnames;
  const accountUrl = pathnames.UserAccount;
  const ordersUrl = pathnames.ListOrders;
  const listSchedulesURL = (authUser.user && authUser.userType === "Nurse") ? pathnames.ListSchedules : pathnames.ListAppointments;
  const AgendaURL = (authUser.user && authUser.userType === "Nurse") ? pathnames.Agenda : pathnames.Landing;

  const handleClick = () => {
    setOpenManageGroup(!openManageGroup);
  };

  return (
    <>
    {(<>
      <Divider key="nested-list-group"/>
       <List
         sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
         component="nav"
         aria-labelledby="nested-list-subheader"
         subheader={
           <ListSubheader component="div" id="nested-list-subheader">
            Dashboard
           </ListSubheader>
         }
       >
            {authUser.user && authUser.userType === "Nurse" && [
                <NavLink to={AgendaURL} style={{textDecoration:'none'}} key="agenda">
                    <ListItemButton>
                        <ListItemIcon><EditCalendarIcon /></ListItemIcon>
                        <ListItemText primary={<Typography color="textPrimary">Agenda</Typography>} />
                    </ListItemButton>
                </NavLink>
            ]}
            {authUser.user && authUser.userType === "Nurse" && [
                <NavLink to='/payment' style={{textDecoration:'none'}} key="billing">
                    <ListItemButton>
                        <ListItemIcon><PaymentsIcon /></ListItemIcon>
                        <ListItemText primary={<Typography color="textPrimary">Payment</Typography>} />
                    </ListItemButton>
                </NavLink>
            ]}
            <ListItemButton onClick={(e)=>{
                e.stopPropagation();
                handleClick();
                }}>
                <ListItemIcon>
                <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Manage" />
                {openManageGroup ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openManageGroup} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                {listSchedulesURL && [
                    <NavLink to={listSchedulesURL} style={{textDecoration:'none'}} key="group-home">
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon><GridViewOutlinedIcon /></ListItemIcon>
                            <ListItemText primary={<Typography color="textPrimary">Schedule</Typography>} />
                        </ListItemButton>
                    </NavLink>
                ]}
                {authUser.user && authUser.userType === "Nurse" && [
                    <NavLink to='/' style={{textDecoration:'none'}} key="settings">
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon><FormatListBulletedIcon /></ListItemIcon>
                            <ListItemText primary={<Typography color="textPrimary">Treatments</Typography>} />
                        </ListItemButton>
                    </NavLink>
                ]}
                {authUser.user && authUser.userType === "Nurse" && accountUrl && [
                    <NavLink to={accountUrl} style={{textDecoration:'none'}} key="service-account-mobile">
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon><AccountBalanceWalletOutlinedIcon /></ListItemIcon>
                            <ListItemText primary={<Typography color="textPrimary">Service Account</Typography>} />
                        </ListItemButton>
                    </NavLink>
                ]}
                {authUser.user && authUser.userType === "Customer" && [
                    <NavLink to={ordersUrl} style={{textDecoration:'none'}} key="orders">
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemIcon><FormatListBulletedIcon /></ListItemIcon>
                            <ListItemText primary={<Typography color="textPrimary">Orders</Typography>} />
                        </ListItemButton>
                    </NavLink>
                ]}
                </List>
            </Collapse>
       </List>
      </>)}
    </>
  );
}
