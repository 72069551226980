import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import {PageSection} from "../template/TemplateElements";
import Footer from "../template/Footer";

const ForClientPage = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    
    //console.log('authUser ', authUser);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        const defaultPermissions = [];
        const adminPermissions = [];
        for(let permission in config.saas.permissions){
            if(config.saas.permissions[permission].default){
                defaultPermissions.push(permission);
            }
            if(config.saas.permissions[permission].admin){
                adminPermissions.push(permission);
            }
        }
        
        setLoaded(true);
        
    },[authInstance, config.saas.permissions, firestoreInstance]);
    
    return (
        <>
            {loaded?(
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title="For Client" />
                    <Box sx={{ flexGrow: 1, p: 2, width: '100%', backgroundColor: '#B4E0ED'}} >
                     <Grid container spacing={0}>
                           <Grid xs={12} md={6}>
                             <PageSection sx={{backgroundColor: '#B4E0ED'}}>
                                <img src="https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/webpages-media%2Fliving-water-client2.png?alt=media&token=e6354dce-d0c1-4a67-ad12-9027c85080ad" width="50%"/>
                             </PageSection>
                           </Grid>
                           <Grid xs={12} md={6}>
                             <PageSection sx={{backgroundColor: '#B4E0ED'}}>
                                <Typography variant="h3">
                                Choose the right at-home IV Therapy service for you
                                </Typography>
                                <Typography align="left" component="div" sx={{py: 3, fontSize: 23}}>
                                <ul>
                                    <li>IV therapy at your home, hotel or office</li>
                                    <li>Variety of services for wellness and recovery</li>
                                    <li>Personalized IV therapy treatment</li>
                                    <li>Clients are assessed and treated by licensed nurses</li>
                                </ul>
                                </Typography>
                                <Typography component="div" sx={{pt: 2}}>
                                <Button variant="contained">Book Now</Button>
                                </Typography>
                             </PageSection>
                           </Grid>
                         </Grid>
                    </Box>
                    <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default ForClientPage;
