import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Box, Container, Grid, Typography, Stack } from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import Button from '@mui/material/Button';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useNavigate } from "react-router-dom";
import Footer from "../template/Footer";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


const landingSteps = [
    'Service Selection',
    'Scheduling',
    'Appointment Address',
    'Overview & Payment'
  ];

const Landing = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const [imageList, setImageList] = useState([]);

    const currentCircle = useRef(null);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }
        
        setLoaded(true);
    },[authInstance, config.saas.permissions, firestoreInstance]);

    return (
        <>
            {loaded?(
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title="Living Waters" />
                     <Box
                        sx={{
                                backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/webpages-media%2Fcity-header-1.png?alt=media&token=c547b9fe-bfdb-4b5d-acb3-6a492c7d1124')",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                maxHeight: '285px',
                                height: '285px',
                                width: '100%',
                                color: '#FFF'
                            }}
                    >
                        <Stack spacing={0} justifyContent="center" alignItems="center" sx={{height: '100%'}}>
                            
                            <Typography component="div" variant="h3" align="center" sx={{p:2, fontSize: {xs: '30px', md: '40px'}}}>
                            Mobile IV Therapy in your place
                            </Typography>
                            <Typography component="div" variant="h4" align="center" sx={{pt:0,pb:2, fontSize: {xs: '20px', md: '30px'}}}>
                            On-demand Mobile IV therapy delivered to your hotel, office & home.
                            </Typography>
                            
                        </Stack>
                     </Box>
                     <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                     <Grid container spacing={0}>
                           <Grid xs={12} md={6} item={true}>
                           <Stack spacing={0} justifyContent="center" alignItems="center" sx={{height: '100%', width: '100%'}}>
                                <img src="https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/webpages-media%2Ftherapy-office-1.png?alt=media&token=a69e17e3-7398-44f9-92fd-d13254f83fba" style={{maxWidth: '400px', width: '100%', borderRadius: '10px'}}/>
                           </Stack>
                           </Grid>
                           <Grid xs={12} md={6} item={true}>
                           <Stack spacing={0} justifyContent="center" alignItems="center" sx={{height: '100%', width: '100%'}}>
                            <Typography component="div" variant="h5" align="center" sx={{pt:2}}>
                                Recover Fast, Boost Energy, & Promote Wellness with a Mobile IV Service.
                                </Typography>
                                <Typography component="div" align="left" sx={{pb:0}}>
                                    <ul>
                                        <li>
                                        Mobile IV therapy at your home, hotel or office
                                        </li>
                                        <li>
                                        Variety of services for wellness and recovery
                                        </li>
                                        <li>
                                        Personalized IV therapy with vitamins tailored to individual needs, ensuring each IV is uniquely crafted for you
                                        </li>
                                        <li>
                                        Treatments are provided by licensed nurses.
                                        </li>
                                    </ul>
                                </Typography>
                            </Stack>
                           </Grid>
                           {!(authUser.user && authUser.userType === "Nurse") && (
                           <Grid xs={12} md={12} item={true}>
                                <Typography component="div" align="center" sx={{pt:{xs: 2, md: 5}, pb:0}}>
                                <Stack spacing={0} justifyContent="center" alignItems="center">
                                    <Stepper activeStep={0} alternativeLabel sx={{width: {xs: '100%', md: '70%'}}}>
                                        {landingSteps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                        ))}
                                    </Stepper>
                                </Stack>
                                </Typography>
                                <Typography component="div" align="center" sx={{pt:3, pb:1}}>
                                    <Button variant="outlined" startIcon={<EventAvailableIcon />} onClick={() => navigate('/booking')}>
                                        Book Now
                                    </Button>
                                </Typography>
                           </Grid>
                           )}
                           <Grid xs={12} md={12} item={true}>
                                <Grid container spacing={0}>
                                    <Grid xs={12} md={12} item={true}>
                                        <Typography component="div" variant="h5" align="center" sx={{p:2}}>
                                        What customers say
                                        </Typography>
                                    </Grid>
                                    <Grid xs={12} md={4} item={true}>
                                        <Stack spacing={0} justifyContent="center" alignItems="center">
                                            <Typography component="div" align="center" sx={{p:1}}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/webpages-media%2Fcustomer-3.png?alt=media&token=87b2542a-d022-4b57-a103-581abd9a7406' width='50%'/>
                                            </Typography>
                                            <Typography component="div" align="center" sx={{p:1}}>
                                            " Love the convenience of this service no matter what city they are in. Very professional, will come to your home, hotel, wherever. "
                                            </Typography>
                                            <Typography component="div" align="center" sx={{p:1}}>
                                                
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid xs={12} md={4} item={true}>
                                        <Stack spacing={0} justifyContent="center" alignItems="center">
                                            <Typography component="div" align="center" sx={{p:1}}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/webpages-media%2Fcustomer-1.png?alt=media&token=1ac0a1fa-ffb4-4f4a-b073-2fefdff11641' width='50%'/>
                                            </Typography>
                                            <Typography component="div" align="center" sx={{p:1}}>
                                            " Great treatment! Quick appointment through App. Technician was on time. Will book again! "
                                            </Typography>
                                            <Typography component="div" align="center" sx={{p:1}}>
                                                
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid xs={12} md={4} item={true}>
                                        <Stack spacing={0} justifyContent="center" alignItems="center">
                                            <Typography component="div" align="center" sx={{p:1}}>
                                                <img src='https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/webpages-media%2Fcustomer-2.png?alt=media&token=c37e0d2a-6c67-426e-9dea-c3028e3728ca' width='50%'/>
                                            </Typography>
                                            <Typography component="div" align="center" sx={{p:1}}>
                                            " Amazing! Some of the best IV Therapy services I have ever had. Definitely recommend as a fun alternative to going to a spa. "
                                            </Typography>
                                            <Typography component="div" align="center" sx={{p:1}}>
                                                
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                           </Grid>
                     </Grid>
                    </Box>
                    <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Landing;
