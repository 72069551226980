import { AuthContext, FireactContext, SetPageTitle } from "../../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled, TextField } from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { collection, query, where, getDocs, getDoc, doc, limit } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import {APIProvider, Map, Marker, useMapsLibrary, useMap, createMarker} from '@vis.gl/react-google-maps';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

  function PlaceAutocomplete({setMarker, currentCircle, showCircle, setAddressEntered, label, clear, setClear}) {
    const inputRef = useRef();
    const map = useMap();
    const placesLibrary = useMapsLibrary('places');
    const mapsLibrary = useMapsLibrary('maps');
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    
    useEffect(() => {
      if (clear) {
        inputRef.current.value = '';
        //this.locationstandardbasic.value = '';
        //console.log('clear the input');
        setClear(false);
      }
    }, [clear]);

    useEffect(() => {
      if (!placesLibrary) return;
  
      const options = {
        fields: ['geometry', 'name', 'formatted_address']
      };
  
      setPlaceAutocomplete(new placesLibrary.Autocomplete(inputRef.current, options));
  
    }, [placesLibrary, mapsLibrary, map]);
  
    useEffect(() => {
      //console.log('place auto complete');
      if (!placeAutocomplete) return;
      //console.log('place auto complete continue');
      placeAutocomplete.addListener('place_changed', () => {
        const place = placeAutocomplete.getPlace();
        console.log("place ", place);
        if (place.geometry?.viewport) {
          map && map.fitBounds(place.geometry?.viewport);
  
          setMarker(place.geometry.location.lat(), place.geometry.location.lng());
          setAddressEntered(place.formatted_address);
          map && map.setCenter(place.geometry.location);
          if (showCircle) {
            if (currentCircle.current) {
                currentCircle.current.setMap(null);
            }
            currentCircle.current = new mapsLibrary.Circle({
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                map,
                center: place.geometry.location,
                radius: 10000, //10km
            });
          }
        }
      });
    }, [placeAutocomplete]);
  
    return (
      <>
          <TextField id="locationstandardbasic" label={label} variant="standard" inputRef={inputRef} sx={{width: '100%'}}/>
      </>
    )
  }

  function Geocoding() {
    const geocodingLibrary = useMapsLibrary('geocoding');
    useEffect(() => {
      if (!geocodingLibrary) return;
  
      const geocoder = new geocodingLibrary.Geocoder();
      // ...
    }, [geocodingLibrary]);
    return (
      <>GeoCode</>
    )
  }

  const Direction = ({appointment}) => {
    const [distance, setDistance] = useState('');
    const [duration, setDuration] = useState('');
    //const navigate = useNavigate();
    const map = useMap();
    const directionsLibrary = useMapsLibrary('routes');

    const startPosition = appointment.treatment.provider.address
    const endPosition = appointment.address;

    const [directionsService, setDirectionsService] = useState(null);

    useEffect(() => {
      if (!directionsLibrary || !map) return;
      setDirectionsService(directionsLibrary);
    }, [directionsLibrary, map]);

    useEffect(() => {
      if (!directionsService) return;
      const dService = new directionsService.DirectionsService();
      const directionsRenderer = new directionsService.DirectionsRenderer();
      directionsRenderer.setMap(map);

      calculateAndDisplayRoute(dService, directionsRenderer, startPosition, endPosition);

    }, [directionsService]);

    function calculateAndDisplayRoute(directionsService, directionsRenderer, startPosition, endPosition) {
      const selectedMode = 'DRIVING';
      const google = window.google;
    
      directionsService
        .route({
          origin: startPosition,
          destination: endPosition,
          travelMode: 'DRIVING',
          //unitSystem: google.maps.UnitSystem.IMPERIAL
          //unitSystem: google.maps.UnitSystem.METRIC
        })
        .then((response) => {
          directionsRenderer.setDirections(response);
          setDistance(response.routes[0].legs[0].distance.text);
          setDuration(response.routes[0].legs[0].duration.text)
          console.log('response', response);
        })
        .catch((e) => {});
    }

    return (
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Box>
          <Typography component='span' align='left' sx={{fontWeight: 'bold'}}>{duration}</Typography>
          <Typography component='span' align='left' sx={{pl:1, fontSize:'12px'}}>({distance})</Typography>
        </Box>
        <Typography component='div' align='left' sx={{py:1}}>
          <Button variant="outlined" startIcon={<DirectionsCarIcon />} onClick={() => window.open("https://www.google.com/maps/dir/?api=1&origin="+startPosition+"&destination="+endPosition+"&travelmode=driving", "_blank", "noreferrer")}>
            Start
          </Button>
        </Typography>
      </Stack>
    );
  } 
  
  const QueryAddress = ({setMarker, currentCircle}) => {
    const map = useMap();
  
    // triggers loading the places library and returns the API Object once complete (the
    // component calling the hook gets automatically re-rendered when this is
    // the case)
    const placesLibrary = useMapsLibrary('places');
    const mapsLibrary = useMapsLibrary('maps');
  
    const [placesService, setPlacesService] = useState(null);
  
    useEffect(() => {
      if (!placesLibrary || !map) return;
  
      // when placesLibrary is loaded, the library can be accessed via the
      // placesLibrary API object
      setPlacesService(new placesLibrary.PlacesService(map));
      
    }, [placesLibrary, mapsLibrary, map]);
  
    useEffect(() => {
      if (!placesService) return;
        var request = {
          query: 'Los Angeles, CA, USA',
          fields: ['name', 'geometry'],
        };
        console.log('request', request);
        placesService.findPlaceFromQuery(request, function(results, status) {
          console.log('status', status);
          if (status === 'OK') {
            setMarker(results[0].geometry.location.lat(), results[0].geometry.location.lng());
            map.setCenter(results[0].geometry.location);
            if (currentCircle.current) {
              currentCircle.current.setMap(null);
            }
            currentCircle.current = new mapsLibrary.Circle({
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.35,
              map,
              center: results[0].geometry.location,
              radius: 10000, //10km
            });
          }
        });
      // ...use placesService...
    }, [placesService]);
  
    return <></>;
  };

  function AddressInput({setAddressEntered, label, clear, setClear, defaultValue}) {
    const inputRef = useRef();
    const placesLibrary = useMapsLibrary('places');
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    
    useEffect(() => {
      if (clear) {
        inputRef.current.value = '';
        //this.locationstandardbasic.value = '';
        //console.log('clear the input');
        setClear(false);
      }
    }, [clear]);

    useEffect(() => {
      if (!placesLibrary) return;
  
      const options = {
        fields: ['geometry', 'name', 'formatted_address']
      };

      setPlaceAutocomplete(new placesLibrary.Autocomplete(inputRef.current, options));
    }, [placesLibrary]);
  
    useEffect(() => {
      if (!placeAutocomplete) return;
      placeAutocomplete.addListener('place_changed', () => {
        const place = placeAutocomplete.getPlace();
        //console.log("place ", place);
        if (place.geometry?.viewport) {
          setAddressEntered(place.formatted_address); 
        }
      });
    }, [placeAutocomplete]);
  
    return (
      <>
          <TextField id="formattedaddressinput" label={label} variant="outlined" inputRef={inputRef} sx={{width: '100%'}} defaultValue={defaultValue}/>
      </>
    )
  }

  export {
    PlaceAutocomplete,
    QueryAddress,
    Geocoding,
    AddressInput,
    Direction
  };