import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, CardMedia, CardContent, Grid, Paper, Typography, styled } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Stack from '@mui/material/Stack';
import { blueGrey, green } from '@mui/material/colors';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Rating from '@mui/material/Rating';

export default function ProductCard(props) {
  const theme = useTheme();
  return (
    <Card>
      <CardHeader sx={{pb: 0}}
        avatar={
          <CardMedia
              component="img"
              sx={{ maxHeight: 50, maxWidth: 50, bgcolor: green[50], borderRadius: 1, p: '4px', width: 50, height: 50, mr: 1}}
              image={props.service.image}
              alt={props.service.name}
            />
        }
        title={
          <Typography component="div" variant="body1" align="left" sx={{fontWeight: 500}}>
          {props.service.name}
          </Typography>
        }
        subheader={
          <Typography component="span" sx={{display: 'inline-flex', alignItems: 'left', width: '100%'}}>
            <Rating name="read-only" value={4.2} size="small" readOnly precision={0.1}/>
            <Typography
                sx={{ml: 1}}
                component="span"
                variant="subtitle2"
                color="text.secondary"
            >
              4.2 out of 5 (3 ratings)
            </Typography>
          </Typography>
        }
      />
      <CardContent sx={{pb: 0}}>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography component="span" variant="body1" align="left" sx={{fontWeight: 500, fontSize: '1.5rem', color: blueGrey['A700']}}>
            ${props.service.price}
          </Typography>
          {props.service.providers && (
            <Typography variant="caption" color="text.secondary" component="div" align="right" sx={{width: '100%'}}>
            <AvatarGroup 
              renderSurplus={(surplus) => <span>+{surplus.toString()}</span>}
              total={90}
              sx={{
                '& .MuiAvatar-root': { width: 32, height: 32, fontSize: 11 },
              }}
            >
            {props.service.providers.map((item, index) => (
              <Avatar sx={{ width: 32, height: 32 }} alt={item.user.displayName} src={item.user.photoURL} />
            ))}
            </AvatarGroup>
            </Typography>
          )}
        </Stack>
        <Typography variant="body2" component="div" align="left">
        { <div dangerouslySetInnerHTML={{ __html: props.service.description }} /> }
        </Typography>
      </CardContent>
      <CardActions sx={{justifyContent: 'center', mb:1}}>
        <Button variant="outlined" startIcon={<EventAvailableIcon />} onClick={(e)=>{props.showDetails(props.service.provider);}}>
          Book
        </Button>
      </CardActions>
    </Card>
  );
}