import React from 'react';
import styled from 'styled-components';
import {Droppable} from '@hello-pangea/dnd';
import Task from './task';

const Column = (props) => {
    console.log('column ', props.column);
    
    const Container = styled.div`
      margin: 8px;
      border: 1px solid lightgrey;
      border-radius: 2px
    `;
    const Title = styled.h3`
      padding: 8px;
    `;
    const TaskList = styled.div`
      padding: 8px;
    `;
    
    return (
        <Container>
            <Title>{props.column.title}</Title>
            <Droppable droppableId={props.column.id}>
              {(provided) =>
                  <TaskList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {props.tasks.map((task, index) => <Task key={task.id} task={task} index={index}/>)}
                    {provided.placeholder}
                  </TaskList>
              }
            </Droppable>
        </Container>
    )
};

export default Column;
