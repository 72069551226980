import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
// https://firebase.google.com/docs/storage/web/start
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc } from 'firebase/firestore';

const Media = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const [imageList, setImageList] = useState([]);
    
    //console.log('authUser ', authUser);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        // read images from firestore DB
        // add this route to authroutes.json to require logging in
        const imagesRef = collection(firestoreInstance, '/images');
        
        const storage = getStorage();
        // Create a child reference
        const imagesStorageRef = ref(storage, 'images');
        
        // all images
        Promise.all([getDocs(imagesRef)]).then(([imgSnapshot]) => {
            setImageList([]);
            imgSnapshot.forEach(record => {
                const data = record.data();
                const newImage = {
                    file: null,
                    fileName: data.filename,
                    status: "FINISH",
                    storageRef: ref(imagesStorageRef, data.filename),
                    downloadURL: data.url,
                    description: "",
                };
                //console.log("newImage ", newImage);
                setImageList((prevState) => [...prevState, newImage]);
            });
            setLoaded(true);
        }).catch(error => {
            setLoaded(true);
            setError(error.message);
        })
        
    },[authInstance, config.saas.permissions, firestoreInstance]);

    return (
        <>
            {loaded?(
                <Container maxWidth="lx">
                    <SetPageTitle title="Media" />
                    <Paper>
                        {imageList.length > 0 && (
                          <>
                            <Box sx={{ width: '100%', maxHeight: 450, overflowY: 'scroll' }}>
                               <ImageList variant="masonry" cols={3} gap={8}>
                                 {imageList.map((item) => (
                                   <ImageListItem key={item.downloadURL}>
                                     <img
                                       srcSet={`${item.downloadURL}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                       src={`${item.downloadURL}?w=248&fit=crop&auto=format`}
                                       alt={item.title}
                                       loading="lazy"
                                     />
                                   </ImageListItem>
                                 ))}
                               </ImageList>
                            </Box>
                           </>
                        )}
                    </Paper>
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Media;
