import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, Timestamp, orderBy } from 'firebase/firestore';
import Footer from "../template/Footer";
import {PageSection} from "../template/TemplateElements";
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Payment = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const [piechartData, setPiechartData] = useState([]);
    const [orderedProducts, setOrderedProducts] = useState([]);

    const sizing = {
        /*legend: { hidden: true },*/
        height: 400,
    };

    useEffect( () => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }
        
        const fetchData = async () => {
            if (authUser.user) {
                // read from firestore DB
                const schedulesRef = collection(firestoreInstance, '/schedules');
                const scheduleQuery = query(schedulesRef, 
                    where('treatment.provider.user_id', '==', authUser.user.uid),
                    //orderBy("datetime", "desc"),
                    orderBy("scheduleOrderDate", "desc"),
                    orderBy("scheduleOrderTime", "asc"));
    
                const schedules = [];
                await Promise.all([getDocs(scheduleQuery)]).then(([scheduleSnapshot]) => {
                    scheduleSnapshot.forEach(record => {
                        const schedule = record.data();
                        schedule.id = record.id;
                        //console.log('schedule', schedule);
                        schedules.push(schedule);
                    });
                    setLoaded(true);
                }).catch(error => {
                    setLoaded(true);
                    console.log('error', error);
                });
    
                if (schedules.length > 0) {
                    const orderedProducts = Object.groupBy(schedules, schedule => schedule.treatment.product_id);
                    if (orderedProducts) {
                        setOrderedProducts(orderedProducts);
                    }
                    //console.log('orderedProducts',orderedProducts);

                    const productAmount = [];

                    const orderedProductsAmount = Object.keys(orderedProducts).map(key => {
                            const orders = orderedProducts[key];
                            let amount=0;
                            let productName = '';
                            orders.map((item, index) => {
                                amount += item.totalPrice;
                                productName = item.treatment.name;
                            });

                            //console.log('product', amount, orderedProducts[key]);
                            //{ id: 0, value: 10, label: 'series A' },
                            return {label:productName, value: amount};
                        }
                    );
                    //console.log('orderedProductsAmount', orderedProductsAmount);
                    setPiechartData(orderedProductsAmount);
                }
    
                //console.log('schedules', schedules);
    
            }
        }

        fetchData();
        
    },[authInstance, config.saas.permissions, firestoreInstance]);

    return (
        <>
            {loaded?(
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title="Payment" />
                    <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                    <Grid container spacing={0}>
                        <Grid xs={12} md={12}>
                            <PageSection>
                            <Grid container spacing={0}>
                                <Grid xs={12} md={12} sx={{px: {xs:0, md:2}}}>
                                    <Typography component="div" variant="h5" align="center" sx={{pb:1}}>
                                    Your Orders
                                    </Typography>
                                    <Grid container spacing={0}>
                                        <Grid xs={12} md={4}>
                                            <Typography component="div" variant="body1" align="center">
                                            {piechartData && (
                                                <PieChart
                                                series={[
                                                  {
                                                    data: piechartData,
                                                    arcLabel: (item) => `$${item.value}`,
                                                  },
                                                ]}
                                                margin={{ top: 10, bottom: 10, left: 70, right:70 }}
                                                slotProps={{
                                                    legend: {
                                                      direction: 'row',
                                                      position: { vertical: 'top', horizontal: 'middle' },
                                                      padding: 0,
                                                    },
                                                  }}
                                                sx={{
                                                    [`& .${pieArcLabelClasses.root}`]: {
                                                    fill: 'white',
                                                    fontSize: 12,
                                                    },
                                                }}
                                                {...sizing}
                                              />
                                            )}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} md={4}>
                                            <CollapsibleTable orderedProducts={orderedProducts}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </PageSection>
                        </Grid>
                    </Grid>
                    </Box>
                    <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Payment;

function createData(name, calories, fat, price, orders) {
    const historyData = [];
    orders.map((item, index) => {
        historyData.push({date: item.date, customerId: item.fullname, amount: 1});
    });
    return {
      name,
      calories,
      fat,
      price,
      history: historyData,
    };
  }
  
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell align="right">{row.calories}</TableCell>
          <TableCell align="right">{row.fat}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell align="right">Price ($)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.history.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.date}
                        </TableCell>
                        <TableCell>{historyRow.customerId}</TableCell>
                        <TableCell align="right">
                          {Math.round(historyRow.amount * row.price * 100) / 100}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
  Row.propTypes = {
    row: PropTypes.shape({
      calories: PropTypes.number.isRequired,
      fat: PropTypes.number.isRequired,
      history: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number.isRequired,
          customerId: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired,
        }),
      ).isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }).isRequired,
  };
  
function CollapsibleTable(props) {
    const orderedProducts = props.orderedProducts;
    //console.log('orderedProducts', orderedProducts);

    const rows = Object.keys(orderedProducts).map(key => {
            const orders = orderedProducts[key];
            console.log('orders', orders);
            let amount=0;
            let productName = '';
            let numberOfOrders = 0;
            let price = 0.0;
            orders.map((item, index) => {
                amount += item.totalPrice;
                productName = item.treatment.name;
                numberOfOrders ++;
                price = item.treatment.price;
            });

            return createData(productName, numberOfOrders, amount, price, orders);
        }
    );

    console.log('rows', rows);

    return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell align="right">Orders</TableCell>
              <TableCell align="right">Amount&nbsp;($)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }