import React, { useContext, useEffect, useState } from "react";
import { Stack, Divider, Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
    return (
        <Grid container direction="column" alignItems="center" sx={{backgroundColor: '#282828', color: '#FFFFFF', py: {xs: 3, md: 5}}}>
          <Grid item xs={12}>
          <Stack
            direction="row"
            divider={<Divider sx={{bgcolor: '#FFFFFF', my: '8px !important'}} orientation="vertical" flexItem />}
            spacing={1}
          >
            <Button variant="text" sx={{color: '#FFFFFF', textTransform: 'none'}}>About</Button>
            <Button variant="text" sx={{color: '#FFFFFF', textTransform: 'none'}} onClick={
                  () => {
                      navigate('/faq');
                  }}>FAQ</Button>
            <Button variant="text" sx={{color: '#FFFFFF', textTransform: 'none'}}>Terms</Button>
            <Button variant="text" sx={{color: '#FFFFFF', textTransform: 'none'}}>Contact</Button>
          </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{py:2}}>
              {'Copyright © '}{`${new Date().getFullYear()} Living Waters. All rights reserved.`}
            </Typography>
          </Grid>
        </Grid>
    )
}
