import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import {PageSection} from "../template/TemplateElements";
import Footer from "../template/Footer";

const ForProviderPage = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    
    //console.log('authUser ', authUser);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        const defaultPermissions = [];
        const adminPermissions = [];
        for(let permission in config.saas.permissions){
            if(config.saas.permissions[permission].default){
                defaultPermissions.push(permission);
            }
            if(config.saas.permissions[permission].admin){
                adminPermissions.push(permission);
            }
        }
        
        setLoaded(true);
        
    },[authInstance, config.saas.permissions, firestoreInstance]);
    
    return (
        <>
            {loaded?(
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title="For Providers" />
                     <Box sx={{ flexGrow: 1, p: 2, width: '100%', backgroundColor: '#B4E0ED'}} >
                     <Grid container spacing={0}>
                           <Grid xs={12} md={6}>
                             <PageSection sx={{backgroundColor: '#B4E0ED'}}>
                                <img src="https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/webpages-media%2Ffor-service-provider-living-water-rounded.png?alt=media&token=2913e235-8f3a-497c-b237-56cbf92681b1" width="50%"/>
                             </PageSection>
                           </Grid>
                           <Grid xs={12} md={6}>
                             <PageSection sx={{backgroundColor: '#B4E0ED'}}>
                                <Typography variant="h3">
                                Be your own boss
                                </Typography>
                                <Typography component="div" sx={{py: 3, fontSize: 23}}>
                                Join the network of independent wellness professionals to earn 2-3x more than industry standards, when and wherever you want.
                                </Typography>
                                <Typography component="div" sx={{pt: 2}}>
                                <Button variant="contained">Sign Up</Button>
                                </Typography>
                             </PageSection>
                           </Grid>
                         </Grid>
                    </Box>
                    <Box sx={{ flexGrow: 1, p: 2, width: '100%', background: 'linear-gradient(to bottom, #cfd8dc, #b0bec5)'}} >
                     <Grid container spacing={0}>
                           <Grid xs={12} md={12}>
                                <Typography variant="h4" align="center">
                                Provider benefits
                                </Typography>
                           </Grid>
                         </Grid>
                    </Box>
                    <Box sx={{ flexGrow: 1, p: 2, width: '100%', backgroundColor: 'white'}} >
                     <Grid container spacing={0}>
                           <Grid xs={12} md={12}>
                                <Typography variant="h4" align="center">
                                        Simple Process
                                        </Typography>
                                </Grid>
                           <Grid xs={12} md={12} sx={{backgroundColor: 'white'}}>
                                <Grid container spacing={0}>
                                    <Grid xs={12} md={4}>
                                        <PageSection sx={{backgroundColor: 'white'}}>
                                            <img src="https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/webpages-media%2Fagenda.png?alt=media&token=125c70f8-0767-4d4c-9863-22af3defc099" width="100%"/>
                                        </PageSection>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <PageSection sx={{backgroundColor: 'white'}}>
                                            <img src="https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/webpages-media%2Fappointment-map.png?alt=media&token=eaee1c6a-7865-4600-83cd-21c7356471e8" width="100%"/>
                                        </PageSection>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <PageSection sx={{backgroundColor: 'white'}}>
                                            <img src="https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/webpages-media%2Fsms-demo.png?alt=media&token=ac76854e-8e85-4b64-b6d1-96a14cd8493a" width="100%"/>
                                        </PageSection>
                                    </Grid>
                                </Grid>
                           </Grid>
                           <Grid xs={12} md={4}>
                             <PageSection></PageSection>
                           </Grid>
                           <Grid xs={12} md={8}>
                             <PageSection></PageSection>
                           </Grid>
                         </Grid>
                    </Box>
                    <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default ForProviderPage;
