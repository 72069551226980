import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import {PageSection} from "../template/TemplateElements";
import Footer from "../template/Footer";
import {add, subtract} from "livingwaters";
import {FAQ} from "livingwaters-lib";

const Faq = ({loader}) => {

    const { firebaseApp, authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    
    //console.log('authUser ', authUser);
    //console.log('npm', add(2,6), subtract(2,1));

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        const defaultPermissions = [];
        const adminPermissions = [];
        for(let permission in config.saas.permissions){
            if(config.saas.permissions[permission].default){
                defaultPermissions.push(permission);
            }
            if(config.saas.permissions[permission].admin){
                adminPermissions.push(permission);
            }
        }
/*
        //--
        const callDB = async () => {
            const postsRef = collection(firestoreInstance, '/posts');
            const postsQuery = query(postsRef, where('category', '==', 'faq'));
            const postsRes = [];
            var post_id = null;
            var data = null;
    
            await getDocs(postsQuery).then(async postSnapshot => {
                postSnapshot.forEach(record => {
                    post_id = record.id;
                    data = record.data();
                    postsRes.push({post_id: post_id, post:data});
                });
                
            }).catch(err => {
                console.log('error', err);
            });  
    
            //console.log('postsRes', postsRes);

            setLoaded(true);
        }
        callDB();
        //--
 */       
        setLoaded(true);
        //console.log('fire', firestoreInstance);
    },[firebaseApp, authInstance, config.saas.permissions, firestoreInstance]);
    
    return (
        <>
            {loaded ?(
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title="FAQ" />
                    <Box sx={{ flexGrow: 1, p: 2, width: '100%', backgroundColor: '#FFF'}} >
                     <Grid container spacing={0}>
                           <Grid xs={12} md={12}>
                           <FAQ/>
                           </Grid>
                         </Grid>
                    </Box>
                    <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Faq;
