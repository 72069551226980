import React, { useContext, useEffect, useState } from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { blueGrey, green } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";

function TimelineCard(props) {
  const { item, ...other } = props;
  //console.log('item', item);

  const { config } = useContext(FireactContext);
  const navigate = useNavigate();

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Grid container spacing={0}>
            <Grid xs={12} md={4}>
                <CardHeader sx={{px:0, pt:0, cursor: 'pointer'}}
                    avatar={
                        <CardMedia
                        component="img"
                        sx={{ maxHeight: 56, maxWidth: 56, bgcolor: green[50], borderRadius: 1, p: '4px', width: 56, height: 56}}
                        image={item.treatment.image}
                        alt={item.name}
                        />
                    }
                    title={item.treatment.name}
                    subheader={
                        <>
                            <Typography>
                            {item.time}
                            </Typography>
                            <Typography sx={{fontSize: 11}}>
                            ${item.totalPrice}
                            </Typography>
                        </>
                    }
                    onClick={()=>{
                      const id = item.id ? item.id : '';
                      navigate(config.pathnames.Schedule.replace(":scheduleId", id));
                  }}
                />
            </Grid>
            <Grid xs={12} md={8}>
                <Typography color="text.secondary" component="span" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
                    <AccountCircleIcon sx={{mr:1}}/>{item.fullname}
                </Typography>
                <Typography color="text.secondary" component="span" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
                    <PlaceIcon sx={{mr:1}}/>{item.address}
                </Typography> 
                {/*<Typography color="text.secondary" component="span" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
                    <EmailIcon sx={{mr:1}}/>{item.email}
                </Typography>*/}
            </Grid>
            {/*
            <Grid xs={12} md={6}>
                <Typography color="text.secondary" component="span" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
                    <PhoneIcon sx={{mr:1}}/>{item.phone}
                </Typography>
                <Typography color="text.secondary" component="span" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
                    <PlaceIcon sx={{mr:1}}/>{item.address}
                </Typography> 
            </Grid>
            */}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default function ScheduleTimeline(props) {
  const schedules = props.schedules;
  return (
    <Timeline sx={{px: 0, py: 0}}>
      {schedules.map((item, index) => (
        <TimelineItem>
            <TimelineOppositeContent
            sx={{ m: 'auto 0', flex: 0, pl: 0 }}
            align="right"
            variant="body2"
            color="text.secondary"
            >
            {item.date}
            </TimelineOppositeContent>
            <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: '#777' }} />
            <TimelineDot sx={{ backgroundColor: '#777' }} />
            <TimelineConnector sx={{ bgcolor: '#777' }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', pl: 2, pr: 0 }}>
                <TimelineCard item={item}/>
            </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
