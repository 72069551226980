import React, { useEffect, useState, useContext } from "react";
import { Navigate, Outlet, matchRoutes, useLocation } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { AuthContext, FireactContext } from '../lib/core';
import authroutes from '../authroutes.json';

const OtpAuthRoutes = ({ loader }) => {
    const { authUser } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const signInPath = config.pathnames.SignIn;
    const location = useLocation();
    const matches = matchRoutes(authroutes, location)
    
    //console.log('current path ', matches);
    /*
    if (matches === null) {
        // the path doesn't need log in to access
        return <Outlet />;
    }
    */
    if(authUser.checked){
        if(authUser.user !== null){
            return <Outlet />
        }else{
            if (matches === null) {
                // the path doesn't need log in to access
                return <Outlet />;
            }
            return <Navigate to={signInPath+"?re="+document.location.pathname+document.location.search+document.location.hash} />
        }
    }else{
        return (
            <Box mt={10}>
                <Container maxWidth="sm">
                    <Box component="span" m={5} textAlign="center">
                        {loader}
                    </Box>
                </Container>
            </Box>
        )
    }
}

export default OtpAuthRoutes;
