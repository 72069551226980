import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { useQuery, gql } from '@apollo/client';
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled } from "@mui/material";

const client = new ApolloClient({
  uri: 'https://spacex-production.up.railway.app/',
  cache: new InMemoryCache(),
});

const QUERY_COMPANY_INFO = gql`
  query Company {
    company {
      founded
      founder
      name
      summary
    }
  }
`;

function Display() {
  const { loading, error, data } = useQuery(QUERY_COMPANY_INFO);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

    console.log('data ', data);
    
  return (
    <div>
        <h1>SpaceX Launches</h1>
          <Typography variant="body1" align="left" gutterBottom>
            {data.company.summary}
          </Typography>
    </div>
  )
}

const SpaceX = ({loader}) => {
    
    return (
        <>
            <ApolloProvider client={client}>
              <Display />
            </ApolloProvider>
        </>
    )
}

export default SpaceX;
