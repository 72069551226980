import './App.css';
import firebaseConfig from "./firebaseConfig.json";
import { pathnames, AuthProvider, MainMenu, PublicTemplate, ResetPassword, SignIn, SignUp, UserMenu, UserProfile, UserUpdateEmail, UserUpdateName, UserUpdatePassword, UserDelete, FireactProvider, ActionPages, UserAccount } from './lib/core';
import OpenTechPlusTemplate from "./template/OpenTechPlusTemplate";
import NavProvider from "./template/NavProvider";
import { BrowserRouter, Routes, Navigate } from "react-router-dom";
import { Route } from "react-router-dom";
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { CircularProgress, Box } from '@mui/material';
import authMethods from "./authMethods.json";
import { CreateSubscription, ListSubscriptions, PermissionRouter, Settings, SubscriptionMenu, ListUsers, SubscriptionProvider, ListInvoices, ManagePaymentMethods, ChangePlan, CancelSubscription, UpdateBillingDetails } from './lib';
import subPathnames from './pathnames.json';
import SaaSConfig from './config.json';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import Avatar from '@mui/material/Avatar';
import Home from './components/Home';
import SubscriptionHome from './components/Subscription/SubscriptionHome';
import About from './components/About';
import ForClientPage from './components/ForClientPage';
import AdminPanel from './components/AdminPanel';
import Faq from './components/Faq';
import ForProviderPage from './components/ForProviderPage';
import Contact from './components/Contact';
import Privacy from './components/Privacy';
import Search from './components/Search';
import AiImage from './components/AiImage';
import Note from './components/Note';
import Uploader from './components/Uploader';
import Media from './components/Media';
import Dashboard from './components/Dashboard';
import Payment from './components/Payment';
import Activity from './components/Activity';
import Stats from './components/Stats';
import NurseProfile from './components/NurseProfile';
import OrderDetails from './components/OrderDetails';
import Schedule from './components/Schedule';
import Orders from './components/Orders';
import Appointment from './components/Appointment';
import Post from './components/Group/Post';
import OtpAuthRoutes from './components/OtpAuthRoutes';
import { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Landing from './components/Landing';
import Agenda from './components/Agenda';

const Brand = "Living Waters";

const Logo = ({size, color}) => {
    const logoColor = color || 'warning';
    return (
        <Avatar
          alt="Living Waters"
          src="/logo192.png"
          sx={{ width: 56, height: 56 }}
        />
    );
}

const Loader = ({size}) => {
    let cpSize = "60px";
    switch(size){
        case "small":
            cpSize = "30px";
            break;
        case "medium":
            cpSize = "35px";
            break;
        case "large":
            cpSize = "60px";
            break;
        default:
            cpSize = "35px";
            break;
    }
    return (
        <Box sx={{ mt: 5, display: 'flex', justifyContent: "center", alignItems: "center"}}>
            <CircularProgress color="warning" size={cpSize} />
            <div style={{position: "absolute" }}>
                <Logo size={size} />
            </div>
        </Box>
    );
}

function App() {

    // merge pathnames
    for(var key in subPathnames){
        pathnames[key] = subPathnames[key];
    }

    const config = {
        firebaseConfig: firebaseConfig,
        brand: "Living Waters",
        pathnames: pathnames,
        authProviders: authMethods,
        saas: SaaSConfig
    }
    
    const [extensionMenu, setExtensionMenu] = useState([]);
    
    const enableEM = (name) => {
        //console.log("name ", name);
        /*
         Push value at the end:

         setArray(oldArray => [...oldArray,newValue] );
         Push value at the start:

         setArray(oldArray => [newValue,...oldArray] );
        */
        if (name) {
            setExtensionMenu(extensionMenu => [...extensionMenu, name]);
        } else {
            setExtensionMenu([]);
        }
        return;
    }
    
    const disableEM = () => {
        //console.log("disableEM ");
        setExtensionMenu([]);
        return;
    }
    
    const CustomItems = () => {
        return (
            <NavLink to="/" style={{textDecoration:'none'}} key="custom">
                <ListItemButton>
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    <ListItemText primary={<Typography color="textPrimary">Custom</Typography>} />
                </ListItemButton>
            </NavLink>
        );
    }

    const themeLight = createTheme({
      palette: {
        primary: {
            main: "#181818",
            /*main: '#00ab6b',
            light: '#14bc7d',
            dark: '#029c68',
            contrastText: '#fff',*/
        }
      }
    });
                
    return (
        <ThemeProvider theme={themeLight}>
        <FireactProvider config={config}>
            <AuthProvider firebaseConfig={firebaseConfig} brand={Brand}>
                <BrowserRouter>
                    <Routes>
                        <Route element={<OtpAuthRoutes loader={<Loader size="large" />} />} >
                            <Route element={<OpenTechPlusTemplate logo={<Logo size="large" />} toolBarMenu={<UserMenu />} extensionMenu={extensionMenu}  drawerMenu={<MainMenu  customItems={<CustomItems />} />} />}>

                                //with default Nav/Menu and extension
                                <Route element={<NavProvider loader={<Loader size="large" />} />} >
                                    <Route exact path={pathnames.Subscription+"/"} element={<SubscriptionHome loader={<Loader size="large" />} enableExtensionMenu={enableEM} />} />
                                </Route>
                                
                                //with default Nav/Menu
                                <Route element={<NavProvider loader={<Loader size="large" />} enableExtensionMenu={disableEM} />} >
                                    <Route exact path="/" element={<Landing loader={<Loader size="large" />} />} />
                                    <Route exact path="/booking" element={<Home loader={<Loader size="large" />} />} />
                                    <Route exact path="/about" element={<About loader={<Loader size="large" />} />} />
                                    <Route exact path="/client" element={<ForClientPage loader={<Loader size="large" />} />} />
                                    <Route exact path="/admin" element={<AdminPanel loader={<Loader size="large" />} />} />
                                    <Route exact path="/faq" element={<Faq loader={<Loader size="large" />} />} />
                                    <Route exact path="/provider" element={<ForProviderPage loader={<Loader size="large" />} />} />
                                    <Route exact path="/contact" element={<Contact loader={<Loader size="large" />} />} />
                                    <Route exact path="/privacy" element={<Privacy loader={<Loader size="large" />} />} />
                                    <Route exact path="/search" element={<Search loader={<Loader size="large" />} />} />
                                    <Route exact path="/note" element={<Note loader={<Loader size="large" />} />} />
                                    <Route exact path="/uploader" element={<Uploader loader={<Loader size="large" />} />} />
                                    <Route exact path="/media" element={<Media loader={<Loader size="large" />} />} />
                                    <Route exact path="/stats" element={<Stats loader={<Loader size="large" />} />} />
                                    <Route exact path="/ai" element={<AiImage loader={<Loader size="large" />} />} />
                                    <Route exact path={pathnames.ListSubscriptions} element={<ListSubscriptions loader={<Loader size="large" />} />} />
                                    <Route exact path={pathnames.ListNurse} element={<Navigate to="/" />} />
                                    <Route exact path={pathnames.NurseProfile} element={<NurseProfile loader={<Loader size="large" />} />} />
                                    <Route exact path={pathnames.OrderDetails} element={<OrderDetails loader={<Loader size="large" />} />} />
                                    <Route exact path={pathnames.Schedule} element={<Schedule loader={<Loader size="large" />} />} />
                                    <Route exact path={pathnames.ListSchedules} element={<Dashboard loader={<Loader size="large" />} />} />
                                    <Route exact path={pathnames.Agenda} element={<Agenda loader={<Loader size="large" />} />} />
                                    <Route exact path={pathnames.ListOrders} element={<Orders loader={<Loader size="large" />} />} />
                                    <Route exact path={pathnames.Appointment} element={<Appointment loader={<Loader size="large" />} />} />
                                    <Route exact path={pathnames.ListAppointments} element={<Activity loader={<Loader size="large" />} />} />
                                    <Route exact path='/payment' element={<Payment loader={<Loader size="large" />} />} />
                                    <Route exact path={pathnames.CreateSubscription} element={<CreateSubscription />} />
                                    <Route exact path={pathnames.UserProfile} element={<UserProfile />} />
                                    <Route exact path={pathnames.UserUpdateEmail} element={<UserUpdateEmail />} />
                                    <Route exact path={pathnames.UserUpdateName} element={<UserUpdateName />} />
                                    <Route exact path={pathnames.UserUpdatePassword} element={<UserUpdatePassword />} />
                                    <Route exact path={pathnames.UserDelete} element={<UserDelete />} />
                                        <Route exact path={pathnames.UserAccount} element={<UserAccount  loader={<Loader size="large" />} />} />
                                </Route>
                                //with Nav/Menu extension
                                //subscription section
                                <Route path={pathnames.Subscription} element={<SubscriptionProvider loader={<Loader size="large" />} enableExtensionMenu={enableEM} />} >
                                    {/*
                                        <Route element={<PermissionRouter permissions={["access"]} />} >
                                            <Route exact path={pathnames.Subscription+"/"} element={<SubscriptionHome />} />
                                        </Route>
                                    */}
                                        <Route element={<PermissionRouter permissions={["access"]} />} >
                                            <Route exact path={pathnames.Post} element={<Post loader={<Loader size="large" />} />} />
                                        </Route>
                                        <Route element={<PermissionRouter permissions={["admin"]} />} >
                                            <Route exact path={pathnames.Settings} element={<Settings loader={<Loader size="large" />} />} />
                                            <Route exact path={pathnames.ListUsers} element={<ListUsers loader={<Loader size="large" />} />} />
                                            <Route exact path={pathnames.ListInvoices} element={<ListInvoices loader={<Loader size="large" />} />} />
                                            <Route exact path={pathnames.ManagePaymentMethods} element={<ManagePaymentMethods loader={<Loader size="large" />} />} />
                                            <Route exact path={pathnames.UpdateBillingDetails} element={<UpdateBillingDetails loader={<Loader size="large" />} />} />
                                            <Route exact path={pathnames.ChangePlan} element={<ChangePlan />} />
                                            <Route exact path={pathnames.CancelSubscription} element={<CancelSubscription />} />
                                        </Route>
                                </Route>
                            </Route>
                        </Route>
                                     
                        <Route element={<PublicTemplate />}>
                            <Route path={pathnames.SignIn} element={
                                <SignIn
                                    logo={<Logo size="large" />}
                                />
                            } />
                            <Route path={pathnames.SignUp} element={
                                <SignUp
                                    logo={<Logo size="large" />}
                                />
                            } />
                            <Route path={pathnames.ResetPassword} element={
                                <ResetPassword
                                    logo={<Logo size="large" />}
                                />
                            } />
                            <Route path={pathnames.ActionPages} element={
                                <ActionPages
                                    logo={<Logo size="large" />}
                                />
                            } />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </FireactProvider>
        </ThemeProvider>
    )
}

export default App;
