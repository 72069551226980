import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import {PageSection} from "../template/TemplateElements";
import Footer from "../template/Footer";
import {add, subtract} from "livingwaters";
//import {AdminConsole} from "livingwaters-lib";
import AdminNav from "./AdminNav";

const AdminPanel = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const [admin, setAdmin] = useState('');

    //console.log('authUser ', authUser);
    //console.log('npm', add(2,6), subtract(2,1));

    useEffect(() => {
        setLoaded(false);
        setError(null);
        //--

        const getAdmin = async () => {
            const adminDoc = doc(firestoreInstance, 'admins', authUser.user.uid);
            await getDoc(adminDoc).then(docSnap => {
                const data = docSnap.data();
                //console.log("Document data:", data);
                if (data != 'undefined') {
                    setAdmin(data.permission);
                }
              }).catch(err => {
                //console.log('err', err);
              });
        }
        if (authUser.user) {
            getAdmin();
        }

        //--
        setLoaded(true);
        
    },[authInstance, config.saas.permissions, firestoreInstance]);
    
    return (
        <>
            {loaded?(
                <Container maxWidth={false} disableGutters>
                <SetPageTitle title="Admin" />
                {authUser.user && authUser.hasOwnProperty('isAdmin') && authUser.isAdmin === true ? (
                    <Box sx={{ flexGrow: 1, p: 0, width: '100%', backgroundColor: '#FFF'}} >
                        {admin == 'super' && (
                                <>
                                <Grid container spacing={0}>
                                    <Grid xs={12} md={3} sx={{background: 'rgb(5, 30, 52)', position: 'sticky', top: 0, alignSelf: 'start', height: {xs: '100%', md: '100vh'}}}>
                                    {/*<AdminConsole AuthContext={AuthContext}></AdminConsole>*/}
                                    <AdminNav/>
                                    </Grid>
                                    <Grid xs={12} md={9}>
                                    <Box sx={{p:2, minHeight: '300vh'}}>
                                            Living Waters Admin Panel
                                        </Box>
                                    </Grid>
                                </Grid>
                                </>
                        )}
                        {admin == 'manager' && (
                            <Box sx={{height: '70vh', p: 10, alignContent: 'center', textAlign: 'center'}}>
                                Business Co-operator Access Permission
                            </Box>
                        )}
                        {admin == '' && (
                            <>
                                Access Permission is required. <br /> info@livingwaters.biz
                            </>
                        )}
                    </Box>
                ) : (
                    <Box sx={{height: '70vh', p: 10, alignContent: 'center', textAlign: 'center'}}>
                        Access Permission is required.
                    </Box>
                )}
                <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default AdminPanel;
