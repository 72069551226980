import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
// https://firebase.google.com/docs/storage/web/start
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, Timestamp, orderBy } from 'firebase/firestore';

import Footer from "../template/Footer";
import {PageSection} from "../template/TemplateElements";

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { blueGrey, green } from '@mui/material/colors';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function AppointmentCard(props) {
  const { item, ...other } = props;
  const [expanded, setExpanded] = React.useState(false);
  const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
  const { config } = useContext(FireactContext);
  const navigate = useNavigate();
  //console.log('item', item);

  return (
    <Card sx={{ width: '100%' }} align="left">
      <CardHeader sx={{cursor: 'pointer'}}
        avatar={
            <CardMedia
              component="img"
              sx={{ maxHeight: 56, maxWidth: 56, bgcolor: green[50], borderRadius: 1, p: '4px', width: 56, height: 56, mr: 1}}
              image={item.treatment.image}
              alt={item.treatment.name}
            />
        }
        title={item.treatment.name}
        subheader={
            <>
                <Typography>
                {`${item.time}, ${item.date}${item.year ? ', '+item.year : ''}`}
                </Typography>
                <Typography sx={{fontSize: 11}}>
                ${item.totalPrice}
                </Typography>
            </>
        }
        onClick={()=>{
            const id = item.id ? item.id : '';
            navigate(config.pathnames.Appointment.replace(":appointmentId", id));
        }}
      />
      {/*
      <CardContent>
        <Typography color="text.secondary" component="span" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
            <AccountCircleIcon sx={{mr:1}}/>{item.treatment.provider.serviceAccount.name}
        </Typography>
        <Typography color="text.secondary" component="span" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
            <PhoneIcon sx={{mr:1}}/>{item.treatment.provider.serviceAccount.phone}
        </Typography>
        <Typography color="text.secondary" component="span" sx={{display: 'inline-flex', alignItems: 'flex-start', width: '100%'}}>
            <EmailIcon sx={{mr:1}}/>{item.treatment.provider.serviceAccount.email}
        </Typography>
      </CardContent>
    */}
    </Card>
  );
}

const Activity = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const [schedules, setSchedules] = useState([]);
    
    //console.log('authUser ', authUser);

    useEffect( () => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        const fetchData = async () => {
            if (authUser.user) {
                // read from firestore DB
                const schedulesRef = collection(firestoreInstance, '/schedules');
                const scheduleQuery = query(schedulesRef, 
                    where('customer.uid', '==', authUser.user.uid),
                    //orderBy("datetime", "desc"),
                    orderBy("scheduleOrderDate", "asc"),
                    orderBy("scheduleOrderTime", "asc"));
    
                const schedules = [];
                await Promise.all([getDocs(scheduleQuery)]).then(([scheduleSnapshot]) => {
                    scheduleSnapshot.forEach(record => {
                        const schedule = record.data();
                        schedule.id = record.id;
                        //console.log('schedule', schedule);
                        schedules.push(schedule);
                    });
                    setLoaded(true);
                }).catch(error => {
                    setLoaded(true);
                    console.log('error', error);
                });
    
                setSchedules(schedules);
            } 
        }
        
        fetchData();
        
    },[authInstance, config.saas.permissions, firestoreInstance]);

    return (
        <>
            {loaded?(
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title="Appointments" />
                    <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                    <Grid container spacing={0}>
                        <Grid xs={12} md={12}>
                            <PageSection>
                            <Grid container spacing={0}>
                                <Grid xs={12} md={12}>
                                    <Typography component="div" variant="h5" align="center" sx={{pb:1}}>
                                    Your Appointments
                                    </Typography>
                                    <Grid container spacing={0}>
                                    {schedules.map((item, index) => (
                                        <Grid xs={12} md={4} sx={{px: {xs:0, md:2}, py: {xs: 1, md: 1}}}>
                                            <AppointmentCard item={item}/>
                                        </Grid>
                                    ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            </PageSection>
                        </Grid>
                    </Grid>
                    </Box>
                    <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Activity;
