import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, CardMedia, CardContent, Container, Grid, Paper, Typography, TextField } from "@mui/material";
import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc, documentId, addDoc, setDoc, arrayUnion, Timestamp, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { useParams, Outlet } from 'react-router-dom';
import InfoDialog from "./InfoDialog";
import ChatDialog from "./ChatDialog";
import ChatIcon from '@mui/icons-material/Chat';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import {PageSection} from "../template/TemplateElements";
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { green } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ProductCard from "./ProductCard";
import SendIcon from '@mui/icons-material/Send';
import Rating from '@mui/material/Rating';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { v4 as uuid, v5 as uuidv5 } from 'uuid';
import ImagesDropzone from "./imagesDropzone";
import ImageElement from "./imageElement";
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs from 'dayjs';
import Link from '@mui/material/Link';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ImageFullScreenDialog from "./ImageFullScreenDialog";

const NurseProfile = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const { nurseSlug } = useParams();
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [openChatDialog, setOpenChatDialog] = useState(false);
    const [dialogInfo, setDialogInfo] = useState(null);
    const [nurseProfile, setNurseProfile] = useState(null);
    const [reviews, setReviews] = useState([]);
    
    //console.log('nurseSlug ', nurseSlug);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        setLoaded(true);
        
    },[authInstance, config.saas.permissions, firestoreInstance]);

    const unsubscribeToReviews = useRef();
    
    const subscribeToReviews = (nurseUid) => {
      //console.log('nurseUid', nurseUid);
      const serviceReviewsRef = collection(firestoreInstance, 'serviceReviews');
      const reviewQuery = query(serviceReviewsRef,
              where('nurseUid', '==', nurseUid),
              orderBy("datetime", "desc"), // this is to enable retrieving the latest reviews
              limit(100),
              //orderBy("datetime", "asc"), //remember to build index
          );
  
        if (!unsubscribeToReviews.current) {
          unsubscribeToReviews.current = onSnapshot(reviewQuery, async (querySnapshot) => {
            const reviews = [];
            const reviewUserIds = [];
            const reviewUsers = [];
            querySnapshot.forEach((doc) => {
              //console.log('doc', doc.data());
              reviews.push(
                doc.data()
              );
              reviewUserIds.push(doc.data().uid);
            });
        
            if (reviewUserIds.length > 0) {
              //get review users
              const usersRef = collection(firestoreInstance, '/users');
              const userQuery = query(usersRef, where(documentId(), 'in', reviewUserIds));
              await getDocs(userQuery).then(async userSnapshot => {
                userSnapshot.forEach(record => {
                  const user = record.data();
                  user.id = record.id;
                  reviewUsers.push(user);
                });
              }).catch(err => {});
            }

            reviews.forEach((reviewData, index) => {
              const newReviewData = reviewData;
              /*
              for (const reviewUser of reviewUsers) {
                //console.log('reviewUser', reviewUser);
                if (reviewUser.id === reviewData.uid) {
                  newReviewData.user = reviewUser;
                  break;
                }
              }*/

              newReviewData.user = reviewUsers.find((reviewUser) => {
                return reviewUser.id === reviewData.uid;
              });

              reviews[index] = newReviewData;
            });
            setReviews(reviews);
            //console.log("reviews", reviews, reviewUsers, reviewUserIds);
          });
        }
    }

    const getNurseProfile = useCallback(async (nurseSlug) => {
        const mResults = [];
        const user = {};
        //get service account info by slug
        const serviceAccountsRef = collection(firestoreInstance, '/userServiceAccounts');
        const serviceAccountQuery = query(serviceAccountsRef, where('slug', '==', nurseSlug));

        await getDocs(serviceAccountQuery).then(async serviceAccountSnapshot => {
            //console.log("serviceAccountSnapshot", serviceAccountSnapshot);
            var user_id = null;
            var data = null;
            serviceAccountSnapshot.forEach(record => {
                user_id = record.id;
                data = record.data();
            });
            
            user.user_id = user_id;
            //user.address =  matchingDoc.data().address

            //--- nurse profile common data ---
            const userRef = doc(firestoreInstance, "users", user_id);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
                user.user = userSnap.data();
                if (user.user.userType === 'Nurse') {
                    //address
                    const placesRef = collection(firestoreInstance, '/places');
                    const placeQuery = query(placesRef, where('users', 'array-contains', user_id));
                    await getDocs(placeQuery).then(async placeSnapshot => {
                        placeSnapshot.forEach(record => {
                            user.address = record.data().address;
                        });
                    });

                    user.serviceAccount = data;
                    //add treatment services
                    const servicesRef = collection(firestoreInstance, '/services');
                    const serviceQuery = query(servicesRef, where('user_id', '==', user_id));
                    
                    await getDocs(serviceQuery).then(async serviceSnapshot => {
                        const productIds = [];
                        serviceSnapshot.forEach(record => {
                            productIds.push(record.data().product_id);
                        });
                        //get the products
                        const treatmentsRef = collection(firestoreInstance, '/treatments');
                        const treatmentQuery = query(treatmentsRef, where(documentId(), 'in', productIds));

                        await getDocs(treatmentQuery).then(treatmentSnapshot => {
                            const products = [];
                            treatmentSnapshot.forEach(record => {
                                products.push(record.data());
                            });
                            user.services = products;
                            //console.log('user', user);
                            mResults.push(user);
                            //console.log('user', user);
                            //setMatchingResults(mResults);
                            //console.log('mResults', mResults);
                        }).catch(err => {});
                    }).catch(err => {});         
                }
            }
            //--- nurse profile common data ---
        }).catch(err => {});  
        //console.log('nurse profile mResults', mResults);
        setNurseProfile(mResults[0]);
        setDialogInfo(mResults[0]);
        if (mResults[0].user_id) {
          subscribeToReviews(mResults[0].user_id);
        }
    }, [nurseSlug]);

    useEffect(() => {
        getNurseProfile(nurseSlug);
    },[getNurseProfile]);

    const handleOpenDialog = (dialogIsOpen) => {
        setOpenInfoDialog(dialogIsOpen);
    }

    const handleOpenChatDialog = (dialogIsOpen) => {
        setOpenChatDialog(dialogIsOpen);
    }

    const showDetails = () => {
        setOpenInfoDialog(true);
    }

    const startChat = () => {
        setOpenChatDialog(true);
    }

    const [openImageFullScreenDialog, setOpenImageFullScreenDialog] = useState(false);
    const [openImageFullScreenDialogURL, setOpenImageFullScreenDialogURL] = useState('');
    const handleClickOpenImageFullScreenDialog = (url) => {
      setOpenImageFullScreenDialogURL(url);
      setOpenImageFullScreenDialog(true);
    }
    const closeImageFullScreenDialog = () => {
      setOpenImageFullScreenDialog(false);
    }

    return (
        <>
            {loaded && nurseProfile ?(
              <>
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title={nurseProfile && nurseProfile.serviceAccount.name} />
                    <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                        <Grid container spacing={0}>
                            <Grid xs={12} md={12}>
                             <Grid container spacing={0}>
                                 <Grid xs={12} md={4}>
                                    <PageSection>
                                      <ProfileCard nurseProfile={nurseProfile} showDetails={showDetails} startChat={startChat} authUser={authUser}/>
                                    </PageSection>
                                 </Grid>
                                 <Grid xs={12} md={4}>
                                    <PageSection>
                                    {authUser.user && (
                                      <Paper sx={{mb: 2, p:2}} >
                                        <ProfileComments nurseProfile={nurseProfile} />
                                      </Paper>
                                    )}
                                    {reviews && reviews.length > 0 ? (
                                      <Paper sx={{ width: '100%'}}>
                                      <Typography align='left' sx={{pl:2, pr:2, pt:2}}>
                                        Customer Reviews
                                      </Typography>
                                      <Box align='left' sx={{pl:2}}>
                                        <Typography component="span" sx={{display: 'inline-flex',alignItems: 'center'}}>
                                          <Rating name="read-only" value={4.2} size="small" readOnly precision={0.1}/>
                                          <Typography
                                              sx={{ml: 1}}
                                              component="span"
                                              variant="subtitle2"
                                              color="text.secondary"
                                          >
                                            4.2 out of 5 (3 ratings)
                                          </Typography>
                                        </Typography>
                                      </Box>
                                      <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
                                        {reviews.map((item, index) => (
                                          <>
                                          <ListItem alignItems="flex-start">
                                            <ListItemAvatar sx={{minWidth: 24, pr:1}}>
                                              <Avatar sx={{ width: 24, height: 24 }} alt={item.user.displayName} src={item.user.photoURL} />
                                            </ListItemAvatar>
                                            <ListItemText
                                              primary={
                                                <React.Fragment>
                                                <Typography component="span" sx={{display: 'inline-flex',alignitems: 'center'}}>
                                                  <Typography
                                                      sx={{mr: 1}}
                                                      component="span"
                                                      variant="subtitle2"
                                                      color="text.primary"
                                                  >
                                                      {item.user.displayName}
                                                  </Typography>
                                                  <Rating name="read-only" value={item.ratingValue} readOnly size="small" precision={0.5}/>
                                                </Typography>
                                                </React.Fragment>
                                              }
                                              secondary={
                                                <React.Fragment>
                                                  <Typography
                                                      sx={{fontSize: 12}}
                                                      component="span"
                                                      variant="subtitle2"
                                                      color="text.secondary"
                                                  >
                                                    {dayjs(new Date(item.datetime.seconds * 1000)).format('MMM D, YYYY')}
                                                  </Typography>
                                                </React.Fragment>
                                              }
                                            />
                                          </ListItem>
                                          <Box sx={{pl:2, pr:2}}>
                                            <Typography align="left">
                                            {item.message}
                                            </Typography>
                                            <Box>
                                              {item.images && 
                                                item.images.length > 1 ? (
                                                  <ImageList variant="masonry" cols={3} gap={8}>
                                                  {item.images && item.images.map((item) => (
                                                    <ImageListItem key={item.url}>
                                                      <img
                                                        srcSet={`${item.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                        src={`${item.url}?w=248&fit=crop&auto=format`}
                                                        loading="lazy"
                                                        style={{cursor:'pointer'}}
                                                        onClick={()=>{handleClickOpenImageFullScreenDialog(item.url)}}
                                                      />
                                                    </ImageListItem>
                                                  ))}
                                                </ImageList>
                                                ) : (
                                                  <>
                                                  {item.images && item.images.map((image) => (
                                                    <img src={image.url} width="100%" loading="lazy" style={{cursor:'pointer'}}
                                                    onClick={()=>{handleClickOpenImageFullScreenDialog(image.url)}}/>
                                                  ))}
                                                  </>
                                                )
                                              }
                                            </Box>
                                          </Box>
                                          {index < (reviews.length - 1) && (<Divider variant="middle" component="li" sx={{mt:1}} />)}
                                          </>
                                        ))}
                                      </List>
                                      </Paper>
                                    ) : (
                                      <Paper sx={{ width: '100%'}}>
                                        <Typography>
                                          Be the first to comment!
                                        </Typography>
                                      </Paper>
                                    )}
                                    </PageSection>
                                 </Grid>
                                 <Grid xs={12} md={4}>
                                    <PageSection>
                                    <Paper>
                                      <Typography align='left' sx={{p:2}}>
                                        Treatments
                                      </Typography>
                                      {nurseProfile.services.map((service, index) => (
                                        <ProductCard service={service} />
                                      ))}
                                      </Paper>
                                    </PageSection>
                                </Grid>
                             </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                <ImageFullScreenDialog openImageFullScreenDialog={openImageFullScreenDialog} closeImageFullScreenDialog={closeImageFullScreenDialog} openImageFullScreenDialogURL={openImageFullScreenDialogURL}/>
              </>
            ):(
                <>{loader}</>
            )}
            <InfoDialog openDialog={openInfoDialog} handleOpenDialog={handleOpenDialog} dialogInfo={dialogInfo} />
            <ChatDialog openDialog={openChatDialog} handleOpenDialog={handleOpenChatDialog} dialogInfo={dialogInfo} />
        </>
    )
}

export default NurseProfile;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export function ProfileCard(props) {
  const [expanded, setExpanded] = React.useState(false);
  const nurseProfile = props.nurseProfile;
  const authUser = props.authUser;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader align="left"
        avatar={
            <Avatar alt={nurseProfile.serviceAccount ? nurseProfile.serviceAccount.name : nurseProfile.user.displayName} src={nurseProfile.user.photoURL}/>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={nurseProfile.serviceAccount ? nurseProfile.serviceAccount.name : nurseProfile.user.displayName}
        subheader={
          <>
          {nurseProfile.serviceAccount && nurseProfile.serviceAccount.license && (
              <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="body2">
                  {nurseProfile.serviceAccount && nurseProfile.serviceAccount.license}
              </Typography>
              <Typography>
                  <Link href="https://www.nursys.com/LQC/LQCSearch.aspx" target="_blank"><VerifiedUserIcon sx={{color: green[800]}}/></Link>
              </Typography>
              </Stack>
          )}
          </>
        }
      />
      {/*<CardMedia
        component="img"
        height="194"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"
      />*/}
      <CardContent align="left" sx={{pt:0}}>
        <Typography variant="body2" color="text.secondary">
          This impressive paella is a perfect party dish and a fun meal to cook
          together with your guests. Add 1 cup of frozen peas along with the mussels,
          if you like.
        </Typography>
      </CardContent>
      <CardActions sx={{pl:2, pr:2}}>
        {((authUser.user && (authUser.user.uid != nurseProfile.user_id) && (authUser.userType != 'Nurse')) || (!authUser.user)) && (
          <>
            <Button variant="outlined" size="small" startIcon={<EventAvailableIcon />} onClick={(e)=>{props.showDetails();}}>
                Schedule
            </Button>
            <Button variant="outlined" size="small" startIcon={<ChatIcon />} onClick={(e)=>{props.startChat();}}>
                Message
            </Button>
          </>
        )}
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and set
            aside for 10 minutes.
          </Typography>
          <Typography paragraph>
            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over
            medium-high heat. Add chicken, shrimp and chorizo, and cook, stirring
            occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a
            large plate and set aside, leaving chicken and chorizo in the pan. Add
            pimentón, bay leaves, garlic, tomatoes, onion, salt and pepper, and cook,
            stirring often until thickened and fragrant, about 10 minutes. Add
            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
          </Typography>
          <Typography paragraph>
            Add rice and stir very gently to distribute. Top with artichokes and
            peppers, and cook without stirring, until most of the liquid is absorbed,
            15 to 18 minutes. Reduce heat to medium-low, add reserved shrimp and
            mussels, tucking them down into the rice, and cook again without
            stirring, until mussels have opened and rice is just tender, 5 to 7
            minutes more. (Discard any mussels that don&apos;t open.)
          </Typography>
          <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then serve.
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export function ProfileComments(props) {
  const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
  const { config } = useContext(FireactContext);
  const { authUser } = useContext(AuthContext);
  const nurseProfile = props.nurseProfile;
  const [ratingValue, setRatingValue] = React.useState(null);
  const [imageList, setImageList] = useState([]);
  const [message, setMessage] = useState('');
  const [clearPreviewImages, setClearPreviewImages] = useState(false);

  /*
  useEffect(() => {
    // read images from firestore DB
    // add this route to authroutes.json to require logging in
    const imagesRef = collection(firestoreInstance, '/serviceReviews');
    const imgQuery = query(imagesRef, where('uid', '==', authInstance.currentUser.uid));
    
    const storage = getStorage();
    // Create a child reference
    const imagesStorageRef = ref(storage, 'service-review-images');
    
  },[authInstance, config.saas.permissions, firestoreInstance]);
  */

  const handleMessageChange = e => {
    setMessage(e.target.value);
  };

  const inputRef = useRef();
  const [submitLoading, setSubmitLoading] = useState(false);
  const submit = async () => {
    console.log('authUser', authUser, 'nurseProfile', nurseProfile, 'ratingValue', ratingValue, 'imageList', imageList, 'message', message);
    if (ratingValue !== null || imageList.length > 0 || message !== '') {
      if (authInstance && firestoreInstance) {
        const serviceReviewsRef = collection(firestoreInstance, '/serviceReviews');
        
        const storage = getStorage();
        // Create a child reference
        const imagesStorageRef = ref(storage, 'service-review-images');

        //1. write into firestore
        setSubmitLoading(true);
        await addDoc(serviceReviewsRef, {
          ratingValue: ratingValue,
          uid: authUser.user.uid,
          nurseUid: nurseProfile.user_id,
          message: message,
          datetime: Timestamp.now(),
        }).then((docRef) => {
          if (imageList.length > 0) {
            //2. upload images and get URL
            //const reviewImages = [];
            imageList.forEach((image, index) => {
              const imageFileName = uuid();
              const storageRef = ref(imagesStorageRef, imageFileName);
              const uploadTask = uploadBytesResumable(storageRef, image.file);
              uploadTask.on('state_changed',
                  (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    //console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                      case 'paused':
                        //console.log('Upload is paused');
                        break;
                      case 'running':
                        //console.log('Upload is running');
                        break;
                    }
                  },
                  (error) => {
                    // Handle unsuccessful uploads
                  },
                  () => {
                      // Handle successful uploads on complete
                      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        const imageInfo = {
                          filename: imageFileName,
                          url: downloadURL,
                          uid: authUser.user.uid
                        };
                        //reviewImages.push(imageInfo);
                        setDoc(docRef, { images: arrayUnion(imageInfo) }, { merge: true });
                        //console.log('uploaded image', imageInfo);
                        setRatingValue(null);
                        setMessage('');
                        inputRef.current.value = '';
                        setClearPreviewImages(true);
                        setSubmitLoading(false);
                      });
                  }
              );
            });
          } else {
            setRatingValue(null);
            setMessage('');
            inputRef.current.value = '';
            setSubmitLoading(false);
          }
        }).catch(err => {
          setSubmitLoading(false);
        });

      }
      //console.log('save to db');
    }
  }

  return (
    <>
    <Box align="left">
      <Typography component="legend">How would you rate it?</Typography>
      <Rating name="no-value" value={ratingValue} precision={0.5}
        onChange={(event, newValue) => {
          setRatingValue(newValue);
        }}
      />
    </Box>
    <Box align="left">
      <Typography component="legend">Share a photo</Typography>
      <ImagesDropzone setImageList={setImageList} clearPreviewImages={clearPreviewImages} setClearPreviewImages={setClearPreviewImages} />
    </Box>
    <TextField
          id="outlined-multiline-static-comments"
          label="Write your review"
          multiline
          rows={4}
          placeholder="What did you like or dislike? What did you think of the service?"
          sx={{ width: '100%', bgcolor: 'background.paper', mt:2}}
          onChange={handleMessageChange}
          inputRef={inputRef}
    />
    <Box sx={{textAlign: "right"}}>
        <LoadingButton
          loading={submitLoading}
          loadingPosition="start"
          startIcon={<SendIcon />}
          variant="outlined"
          onClick={submit}
          sx={{mt: 1}}
        >
          Submit
        </LoadingButton>
    </Box>
    </>
  );
}
