import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled, TextField } from "@mui/material";
import Fab from '@mui/material/Fab';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import Messaging from './Messaging';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StaticDatePicker, StaticDateTimePicker } from '@mui/x-date-pickers';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Avatar from '@mui/material/Avatar';
import dayjs from 'dayjs';
import { getAuth, signInAnonymously } from "firebase/auth";
import LoginIcon from '@mui/icons-material/Login';
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, updateDoc, orderBy, startAt, endAt, and, or, limit, onSnapshot, Timestamp } from 'firebase/firestore';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import {CustomizedInputBase} from "./util";
import './Chat.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChatDialog(props) {
  const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
  const { config } = useContext(FireactContext);
  const signInPath = config.pathnames.SignIn;
  const pathnames = config.pathnames;
  const { authUser } = useContext(AuthContext);
  
  //console.log('authUser ', authUser);

  const [open, setOpen] = React.useState(false);
  const [selectedDay, setSelectedDay] = React.useState(dayjs(new Date()));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /*
  const loginAnonymously = () => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then( result => {
        // Signed in..
        const user = result.user;
        console.log('user', user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  }
  */

  useEffect(() => {
    if (props.openDialog) {
        setOpen(props.openDialog);
        props.handleOpenDialog(false);
    }
  }, [props.openDialog]);

  /*
  useEffect(() => {
    if (props.dialogInfo) {
        console.log('info', props.dialogInfo);
    }
  }, [props.dialogInfo]);
  */

  const Item = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center'
  }));

  const FlexboxItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    width: '150px',
  }));

  const selectDay = (day) => {
    setSelectedDay(day);
  }

  useEffect(() => {
    //console.log('selectedDay', selectedDay, selectedDay.format('YYYY-MM-DD'));
  }, [selectedDay]);

  useEffect(() => {
    //console.log("open dialog", authUser.user, props.openDialog);
    if (authUser.user && props.openDialog && authUser.user.uid && props.dialogInfo.user_id) {
      //console.log("start chatting");
      subscribeToNewMessages();
    }
  }, [authUser.user, props.openDialog]);

  const [chatMessages, setChatMessages] = React.useState([]);
  const messages = useRef([]);
  const unsubscribeToChat = useRef();

  const subscribeToNewMessages = () => {
    if (unsubscribeToChat.current) {
      //console.log('unsubscribe');
      unsubscribeToChat.current();
    }
    const messagesRef = collection(firestoreInstance, '/messages');
    const messageQuery = query(messagesRef, 
            or(
              and(where('receiver', '==', props.dialogInfo.user_id), where('sender', '==', authUser.user.uid)),
              and(where('receiver', '==', authUser.user.uid), where('sender', '==', props.dialogInfo.user_id))
            ),
            orderBy("datetime", "desc"), // this is to enable retrieving the latest messages
            limit(100),
            //orderBy("datetime", "asc"), //remember to build index
        );

      unsubscribeToChat.current = onSnapshot(messageQuery, (querySnapshot) => {
      messages.current = [];
      querySnapshot.forEach((doc) => {
        messages.current.push({
          id: doc.id,
          ...doc.data(),
        });
      });
  
      setChatMessages(messages.current);

      //console.log("authUser.user.uid", authUser.user.uid, "messages.current", messages.current);
    });

    //console.log('unsubscribe', unsubscribeToChat.current);
  }
  
  const messageInputRef = useRef();

  const sendMessage = async () => {
    const messagesRef = collection(firestoreInstance, '/messages');
    
    const docRef = await addDoc(messagesRef, {
      receiver: props.dialogInfo.user_id,
      sender: authUser.user.uid,
      text: messageInputRef.current.value,
      datetime: Timestamp.now(),
    });

    messageInputRef.current.value = '';

    /*console.log('message sent', {
      receiver: props.dialogInfo.user_id,
      sender: authUser.user.uid,
      text: messageInputRef.current.value,
      datetime: Timestamp.now(),
    });*/
  }

  const ref = React.useRef(null);

  const updatePosition = () => {
    //console.log('chat with ref', ref.current);
    if (ref.current) {
        //console.log('chat with scroll', ref.current);
        ref.current.scrollTop = 10000;//ref.current.offsetHeight;
        //ref.current.ownerDocument.body.scrollTop = ref.current.ownerDocument.body.offsetHeight;
    }
  }

  useEffect(() => {
    updatePosition();
}, [chatMessages]);

  return (
    <div>
      <Dialog
          fullWidth
          maxWidth="md"
          sx={{
          "& .MuiDialog-paper": {
              margin: 0,
              width: "100%",
              'border-radius': 0,
              position: "absolute",
              bottom:0,
              height: "80vh"
          }
          }}
          BackdropProps={{
            invisible: true
          }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description-info"
      >
        <DialogTitle>
            <Stack direction="row" spacing={2}>
                <Item>
                    <Avatar alt={props.dialogInfo && props.dialogInfo.user.displayName} src={props.dialogInfo && props.dialogInfo.user.photoURL} />
                </Item>
                <Item>
                    Talk to {props.dialogInfo && props.dialogInfo.user.displayName}
                </Item>
            </Stack>
            <ExpandMoreOutlinedIcon onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 18,
              }}
            />
        </DialogTitle>
        <DialogContent ref={ref}>
            <Grid container spacing={0}>
                <Grid xs={12} md={12}>
                  <Box>
                    {authUser.user !== null ? (
                      <Box sx={{ pb: 7 }}>
                        <CssBaseline />
                        <div className="message-container"> 
                        {chatMessages.slice().reverse().map((item) => (
                          <div className={`message ${item.sender == authUser.user.uid ? 'receiver-message':'sender-message'}`}> 
                            {item.sender != authUser.user.uid &&
                              <img className="avatar" src={item.sender == authUser.user.uid ? authUser.user.photoURL : props.dialogInfo && props.dialogInfo.user.photoURL}/>
                            }
                            {item.text}
                          </div>
                        ))}
                        </div>
                        <Paper sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} elevation={3}>
                            <BottomNavigation>
                              <CustomizedInputBase sendMessage={sendMessage} inputRef={messageInputRef} />
                            </BottomNavigation>
                        </Paper>
                      </Box>
                    ) : (
                      <>
                      <Button aria-label="sign in" color="inherit" sx={{textTransform: 'none'}} 
                      onClick={(e)=>{
                          e.preventDefault();
                          document.location.href = signInPath+"?re="+document.location.pathname+document.location.search+document.location.hash
                        }}>
                        <LoginIcon sx={{marginRight: "5px"}}/> Sign In
                      </Button>
                       to leave messages to {props.dialogInfo && props.dialogInfo.user.displayName}</>
                    )}
                  </Box>
                </Grid>
            </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
