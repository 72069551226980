import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import ScheduleTimeline from "./ScheduleTimeline";
// https://firebase.google.com/docs/storage/web/start
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, Timestamp, orderBy } from 'firebase/firestore';
import Footer from "../template/Footer";
import {PageSection} from "../template/TemplateElements";
import {APIProvider, Map, Marker, useMapsLibrary, useMap, createMarker} from '@vis.gl/react-google-maps';
import {QueryAddress, PlaceAutocomplete} from "./google_maps/MapComponents";
import * as geofire from 'geofire-common';

const Dashboard = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const [schedules, setSchedules] = useState([]);
    const [tabValue, setTabValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect( () => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        const fetchData = async () => {
            if (authUser.user) {
                // read from firestore DB
                const schedulesRef = collection(firestoreInstance, '/schedules');
                const scheduleQuery = query(schedulesRef, 
                    where('treatment.provider.user_id', '==', authUser.user.uid),
                    //orderBy("datetime", "desc"),
                    orderBy("scheduleOrderDate", "asc"),
                    orderBy("scheduleOrderTime", "asc"));
    
                const schedules = [];
                await Promise.all([getDocs(scheduleQuery)]).then(([scheduleSnapshot]) => {
                    scheduleSnapshot.forEach(record => {
                        const schedule = record.data();
                        schedule.id = record.id;
                        //console.log('schedule', schedule);
                        schedules.push(schedule);
                    });
                    setLoaded(true);
                }).catch(error => {
                    setLoaded(true);
                    console.log('error', error);
                });
    
                setSchedules(schedules);
            }
        }

        fetchData();
        
    },[authInstance, config.saas.permissions, firestoreInstance]);

    return (
        <>
            {loaded?(
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title="Schedules" />
                    <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                    <Grid container spacing={0}>
                        <Grid xs={12} md={12}>
                            <PageSection>
                            <Grid container spacing={0}>
                                <Grid xs={12} md={12} sx={{px: {xs:0, md:2}}}>
                                    <Typography component="div" variant="h5" align="center" sx={{pb:1}}>
                                    Your Schedules
                                    </Typography>
                                    <Grid container spacing={0}>
                                        <Grid xs={12} md={12}>
                                            <Typography component="div" variant="body1" align="left" sx={{fontWeight: 500}}>
                                                {schedules.length > 0 && (
                                                    <ScheduleTimeline schedules={schedules}/>
                                                )}
                                            </Typography>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </PageSection>
                        </Grid>
                    </Grid>
                    </Box>
                    <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Dashboard;
