import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { DragDropContext } from '@hello-pangea/dnd';
import initialData from './drag_and_drop/initial-data';
import Column from './drag_and_drop/column';

const Note = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [state, setState] = useState(initialData);
    const { authUser } = useContext(AuthContext);
    
    //console.log('authUser ', authUser);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        setLoaded(true);
        
    },[authInstance, config.saas.permissions, firestoreInstance]);
    
    const onDragEnd = result => {
      const {destination, source, draggableId } = result;

      if(!destination) {
        return;
      }

      if (destination.droppableId === source.droppableId && destination.index === source.index) {
        return;
      }

      const column = state.columns[source.droppableId];
      const newTaskIds = Array.from(column.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...column,
        taskIds: newTaskIds,
      };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      };

      setState(newState);
    };

    return (
        <>
            {loaded?(
                <Container maxWidth="lx">
                    <SetPageTitle title="Notes" />
                    <Paper>
                        <DragDropContext onDragEnd={onDragEnd}>
                         {
                             state.columnOrder.map((columnId) => {
                                 const column = state.columns[columnId];
                                 const tasks = column.taskIds.map(taskId => state.tasks[taskId]);
                                 //console.log('raw column data ', column);
                                 return (<Column key={column.id} column={column} tasks={tasks} />);
                               })
                         }
                        </DragDropContext>
                     
                    </Paper>
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Note;
