import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint, Timestamp, orderBy, and, documentId } from 'firebase/firestore';
import { Alert, Box, Button, Card, CardActions, CardHeader, CardContent, Container, Grid, Paper, Typography, styled, TextField, Divider } from "@mui/material";
import Stack from '@mui/material/Stack';
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useNavigate } from "react-router-dom";
import Footer from "../template/Footer";
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import SaveIcon from '@mui/icons-material/Save';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import ScheduleIcon from '@mui/icons-material/Schedule';
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import { red, grey } from '@mui/material/colors';
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import MoreTimeIcon from '@mui/icons-material/MoreTime';

dayjs.extend(weekday);
dayjs.extend(isBetweenPlugin);

  const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
  })(({ theme, isSelected, isHovered, day }) => ({
    borderRadius: '50%',
    ...(isSelected && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    }),
    ...(isHovered && {
      backgroundColor: theme.palette.primary[theme.palette.mode],
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary[theme.palette.mode],
      },
    }),
    /*...(day.day() === 0 && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    }),
    ...(day.day() === 6 && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    }),*/
  }));

  const FlexboxItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    width: '150px',
  }));
  
  const FlexdivItem = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 1,
    width: '300px',
  }));

const Agenda = ({loader}) => {
    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const [showPickDates, setShowPickDates] = React.useState(false);
    const [showPickCustomizedDates, setShowPickCustomizedDates] = React.useState(false);
    const [agreementChecked, setAgreementChecked] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [selectedDay, setSelectedDay] = React.useState(dayjs(new Date()));
    const [selectedDays, setSelectedDays] = React.useState([]);

    const [selectedDates, setSelectedDates] = React.useState('');
    const [selectedTimeSlots, setSelectedTimeSlots] = React.useState(() => []);
    const [availableTimeSlots, setAvailableTimeSlots] = React.useState(() => []);
    const [availableTimeSlotsSaved, setAvailableTimeSlotsSaved] = React.useState(() => []);
    const [showConfirmCheckBox, setShowConfirmCheckBox] = React.useState(false);

    const handleSelectedTimeSlots = (event, newTimeSlots) => {
        setSelectedTimeSlots(newTimeSlots);
    };

    const [selectedDatesHelperText, setSelectedDatesHelperText] = React.useState("Please choose the date you're available");

    const selectDay = (day) => {
        setSelectedDay(day);
        setSelectedTimeSlots([]);
    }

    const addCustomizedTimeSlots = () => {
        const timeSlots = [];
        selectedTimeSlots.forEach((timeSlot, index) => {
            timeSlots.push({
                timeSlot: timeSlot,
                day: selectedDay.hour(0).minute(0).second(0).millisecond(0).unix(), // same date
            });
        });
        timeSlots.sort((a,b) => a.timeSlot - b.timeSlot);//in each day
        console.log('timeSlots', timeSlots);

        const selectedDayTimeSlots = availableTimeSlots.findIndex((timeSlotsInADay) => {
            return timeSlotsInADay.day === selectedDay.hour(0).minute(0).second(0).millisecond(0).unix();
        }); // get the day with time slots

        console.log('selectedDayTimeSlots', selectedDayTimeSlots);

        if (selectedDayTimeSlots === -1) {
            // add all time slots
            const dayTimeSlots = {
                slots: timeSlots,
                day: selectedDay.hour(0).minute(0).second(0).millisecond(0).unix(), // same date
            };
            const newAvailableTimeSlots = [...availableTimeSlots, dayTimeSlots];
            newAvailableTimeSlots.sort((a,b) => a.day - b.day);
            //setAvailableTimeSlots(prevState => [...prevState, dayTimeSlots]);
            console.log('add all');
            setAvailableTimeSlots(newAvailableTimeSlots);
        } else {
            //merge time slots to the day
            const newAvailableTimeSlots = [...availableTimeSlots];
            const slots = newAvailableTimeSlots[selectedDayTimeSlots].slots;
            //merge
            timeSlots.forEach((newSelectedTimeSlot, index) => {
                const timeSlotIndex = slots.findIndex((slot) => {
                    return slot.timeSlot === newSelectedTimeSlot.timeSlot;
                });
                if (timeSlotIndex === -1) {
                    //is new selected time slot
                    slots.push(newSelectedTimeSlot);
                }
            });
            slots.sort((a,b) => a.timeSlot - b.timeSlot);//in each day
            console.log('merge');
            setAvailableTimeSlots(newAvailableTimeSlots);
        }
        setSelectedTimeSlots([]);
    }

    const addTimeSlots = () => {
        const timeSlots = [];
        const dayTimeSlotsArray = [];

        selectedTimeSlots.forEach((timeSlot, index) => {
            timeSlots.push({
                timeSlot: timeSlot,
                day: selectedDay.hour(0).minute(0).second(0).millisecond(0).unix(), // same date
            });
        });
        timeSlots.sort((a,b) => a.timeSlot - b.timeSlot);//in each day

        selectedDays.forEach((selectedDay, index) => {
            const dayTimeSlots = {
                slots: timeSlots,
                day: selectedDay.hour(0).minute(0).second(0).millisecond(0).unix(), // same date
            };
            dayTimeSlotsArray.push(dayTimeSlots);
        }); 

        dayTimeSlotsArray.sort((a,b) => a.day - b.day);

        const mergedDayTimeSlotsArray = [...availableTimeSlots];

        // merge dayTimeSlotsArray and availableTimeSlots (availableTimeSlots has the same data as availableTimeSlotsSaved)
        dayTimeSlotsArray.forEach((timeSlot, index) => {
            const selectedDayTimeSlots = availableTimeSlots.findIndex((timeSlotsInADay) => {
                return timeSlotsInADay.day === timeSlot.day;
            }); // get the day with time slots

            if (selectedDayTimeSlots === -1) {
                //1. new date, add all
                mergedDayTimeSlotsArray.push(timeSlot);
            } else {
                //2. same date, add existing time slots
                availableTimeSlots[selectedDayTimeSlots].slots.forEach((existingSlot, index)=>{
                    const timeSlotIndex = timeSlot.slots.findIndex((slot) => {
                        return slot.timeSlot === existingSlot.timeSlot;
                    });
                    if (timeSlotIndex === -1) {
                        //is new selected time slot
                        timeSlot.slots.push(existingSlot);
                    }
                });
                timeSlot.slots.sort((a,b) => a.timeSlot - b.timeSlot);
                //mergedDayTimeSlotsArray.push(timeSlot);
                //find index in mergedDayTimeSlotsArray
                const mergedDayTimeSlotsIndex = mergedDayTimeSlotsArray.findIndex((mergedTimeSlot) => {
                    return timeSlot.day === mergedTimeSlot.day;
                });

                if (mergedDayTimeSlotsIndex !== -1) {
                    mergedDayTimeSlotsArray[mergedDayTimeSlotsIndex] = timeSlot;
                }
            }
        });

        mergedDayTimeSlotsArray.sort((a,b) => a.day - b.day);

        setAvailableTimeSlots(mergedDayTimeSlotsArray);
        setSelectedTimeSlots([]);
    }

    const handleSelectedDatesChange = (event) => {
        setSelectedDates(event.target.value);
        let helperText = "Please choose the date you're available";
        switch (event.target.value) {
            case 'weekdays':
                helperText = "I'm available in weekdays";
                setValue(dayjs(new Date()));
                break;
            case 'weekends':
                helperText = "I'm available in weekends";
                setValue(dayjs(new Date()));
                break;
            case 'customized':
                setSelectedDay(dayjs(new Date()));
                helperText = "I'm available on the days I selected"
                break;
            default:

        } 
        setSelectedDatesHelperText(helperText);
        setAvailableTimeSlots([]);//reset time slots
        setSelectedTimeSlots([]);
    };

    const removeTimeSlot = (aIndex, sIndex) => {
        //availableTimeSlots, index
        if (availableTimeSlots.length > 0) {
            const newAvailableTimeSlots = [...availableTimeSlots];
            const newTimeSlots = [...newAvailableTimeSlots[aIndex].slots];
            newTimeSlots.splice(sIndex, 1); // 2nd parameter means remove one item only
            if (newTimeSlots.length > 0) {
                newAvailableTimeSlots[aIndex].slots = newTimeSlots;
            } else {
                newAvailableTimeSlots.splice(aIndex, 1);
            }
            
            //console.log('aIndex', aIndex, 'sIndex', sIndex, newAvailableTimeSlots, 'selected', newAvailableTimeSlots[aIndex].slots, 'newTimeSlots', newTimeSlots);
            setAvailableTimeSlots(newAvailableTimeSlots);
            setShowConfirmCheckBox(true);
        }
    }

    const bookingTimeSlots = [
        '8:00am - 10:00am',
        '10:00am - 12:00pm',
        '12:00pm - 2:00pm',
        '2:00pm - 4:00pm',
        '4:00pm - 6:00pm',
        '6:00pm - 8:00pm',
        '8:00pm - 10:00pm'
    ];

    const [hoveredDay, setHoveredDay] = React.useState(null);
    const [value, setValue] = React.useState(dayjs(new Date()));

    const handleAgreementCheckChange = (event) => {
        setAgreementChecked(event.target.checked);
    };
    function handleSaveButtonClick() {
        setLoading(true);
        const agendarRef = doc(firestoreInstance, 'agenda/'+authUser.user.uid);
        setDoc(agendarRef,{timeSlots: availableTimeSlots},{merge:true}).then(() => {
            setAvailableTimeSlotsSaved(availableTimeSlots);//use local data
            setLoading(false);
            setIsEditing(false);
            setAgreementChecked(false);
        });
    }

    const handleEditButtonClick = () => {
        setIsEditing(true);
        //setAvailableTimeSlots(availableTimeSlotsSaved); // copy saved time slots
    }

    useEffect(() => {
        if (selectedDates == 'weekdays' || selectedDates == 'weekends') {
            setShowPickDates(true);
        } else {
            setShowPickDates(false);
        }
        if (selectedDates == 'customized') {
            setShowPickCustomizedDates(true);
        } else {
            setShowPickCustomizedDates(false);
        }
        if (selectedDates == '') {
            //arrives at the page
        } else {
            setAvailableTimeSlots(availableTimeSlotsSaved);
        }
        //console.log('selectedDates', selectedDates);
    },[selectedDates]);

    useEffect(() => {
        //console.log('availableTimeSlots', availableTimeSlots);
        //console.log('availableTimeSlotsSaved', availableTimeSlotsSaved);
    },[availableTimeSlots, availableTimeSlotsSaved]);

    useEffect(() => {
        const days = [];
        const Weekdays = ['Mon','Tue','Wed','Thu','Fri'];
        const Weekends =['Sat','Sun'];
        if (selectedDates == 'weekdays') {
            //get all dates in 7 days, except Sat and Sun
            let newValue = value;
            for (let i = 0; i < 7; i++) {
                if (Weekdays.includes(newValue.format('ddd'))) {
                    days.push(newValue);
                }   
                newValue = value.add(i+1, 'day');
            }
        } 
        if (selectedDates == 'weekends') {
            //get all dates in 7 days, keep only Sat and Sun
            let newValue = value;
            for (let i = 0; i < 7; i++) {
                if (Weekends.includes(newValue.format('ddd'))) {
                    days.push(newValue);
                }
                newValue = value.add(i+1, 'day');
            }
        }
        setSelectedDays(days);
        //console.log('availableTimeSlots', availableTimeSlots);
        //console.log('value', value.format('YYYY-MM-DD'));
        //console.log('days', days);
    },[value]);

    const isInSameWeek = (dayA, dayB) => {
        if (dayB == null) {
          return false;
        }
    
        if (dayB.isBefore(dayjs(new Date()).hour(0).minute(0).second(0).millisecond(0))) {
            return false;
        } 
    
        //console.log('dayA', dayA);
        //return dayA.isSame(dayB, 'week');
        let ret = dayA.isAfter(dayB.subtract(1, 'day').hour(0).minute(0).second(0).millisecond(0)) && dayA.isBefore(dayB.add(6, 'day').hour(23).minute(59).second(59).millisecond(0));

        const Weekdays = ['Mon','Tue','Wed','Thu','Fri'];
        const Weekends =['Sat','Sun'];

        if (selectedDates == 'weekdays') {
            const isWeekday = Weekdays.includes(dayA.format('ddd'));
            ret = ret && isWeekday;
        } else if (selectedDates == 'weekends') {
            const isWeekend = Weekends.includes(dayA.format('ddd'));
            ret = ret && isWeekend;
        }

        return ret;
    };
      
    function Day(props) {
        const { day, selectedDay, hoveredDay, ...other } = props;
      
        return (
          <CustomPickersDay
            {...other}
            day={day}
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
          />
        );
    }

    const DisplayTimeSlots = (props) => {
        const { value, index, removeTimeSlot, readOnly, ...other } = props;
        return (
            <Box sx={{pb:2}}>
                <Stack direction="row" spacing={2}>
                    <Box sx={{ position: 'relative', display: 'flex', flexFlow: 'column', justifyContent: 'center', alignContent: 'center', width: '55px', height: '55px'}}>
                        <Typography component="div" color="#FFFFFF" align="center" sx={{bgcolor: red[500], fontSize: 13, fontWeight: 'bold', borderRadius: '8px 8px 0 0', width: '55px', height: '100%', justifyContent: 'center', alignContent: 'center'}}>
                            {dayjs.unix(value.day).format('ddd')}
                        </Typography>
                        <Typography component="div" color="text.secondary" align="center" sx={{fontSize: 13, fontWeight: 'bold', bgcolor: '#e0e0e0', borderRadius: '0 0 8px 8px', width: '55px'}}>
                            {dayjs.unix(value.day).format('MMM DD')}
                        </Typography>
                    </Box>
                    <Typography component="div" color="text.secondary" 
                        sx={(index % 2) ? {borderRadius: '8px', width: '100%', bgcolor: grey[300]} : {borderRadius: '8px', width: '100%', bgcolor: grey.A200}}
                    >
                        <List dense={true} sx={{p:0}}>
                        {value.slots.map((slot, sIndex)=>(
                            <>
                            <ListItem sx={{py:0}}
                            secondaryAction={!readOnly && (<>
                                <IconButton edge="end" aria-label="delete">
                                    <DeleteIcon onClick={()=>{removeTimeSlot(index, sIndex)}}/>
                                </IconButton>
                            </>)
                            }
                            >
                                <ListItemText
                                    primary={bookingTimeSlots[slot.timeSlot]}
                                />
                            </ListItem>
                            {((value.slots.length-1) != sIndex) && <Divider variant="middle"/>}
                            </>
                        ))}
                        </List>
                    </Typography>
                </Stack>
            </Box>
        );
    }

    const getAgenda = useCallback(async () => {
        // read from firestore DB
        const agendaRef = collection(firestoreInstance, '/agenda');
        const agendaQuery = query(agendaRef, 
            where(documentId(), '==', authUser.user.uid));
        let timeSlots = [];
        if (authUser.user) {
            await Promise.all([getDocs(agendaQuery)]).then(([snapshot]) => {
                snapshot.forEach(record => {
                    timeSlots = record.data().timeSlots;
                });
            }).catch(error => {
                console.log('error', error);
            });
            // remove out of date time slots
            const newTimeSlots = timeSlots.filter((slot) => slot.day >= dayjs(new Date()).hour(0).minute(0).second(0).millisecond(0).unix());
            const newTimeSlotsSaved = newTimeSlots.map((timeslot) => {
                if (timeslot.day == dayjs(new Date()).hour(0).minute(0).second(0).millisecond(0).unix()) {
                    //console.log('current hour', dayjs().hour());
                    let timeslotIndexOfCurrentTime = -1;
                    if (dayjs().hour() >= 9) timeslotIndexOfCurrentTime = 0;
                    if (dayjs().hour() >= 11) timeslotIndexOfCurrentTime = 1;
                    if (dayjs().hour() >= 13) timeslotIndexOfCurrentTime = 2;
                    if (dayjs().hour() >= 15) timeslotIndexOfCurrentTime = 3;
                    if (dayjs().hour() >= 17) timeslotIndexOfCurrentTime = 4;
                    if (dayjs().hour() >= 19) timeslotIndexOfCurrentTime = 5;
                    if (dayjs().hour() >= 21) timeslotIndexOfCurrentTime = 6;
                    const filteredTimeSlots = timeslot.slots.filter((slot) => slot.timeSlot > timeslotIndexOfCurrentTime );
                    timeslot.slots = filteredTimeSlots;
                }
                return timeslot;
            });
            //console.log('newTimeSlotsSaved', newTimeSlotsSaved);
            setAvailableTimeSlotsSaved(newTimeSlotsSaved);
        }
    }, [authUser.user]);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        getAgenda();
        setLoaded(true);
    },[authInstance, config.saas.permissions, firestoreInstance, getAgenda]);
    
    return (
        <>
            {loaded?(
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title="Living Waters" />
                     <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                     {(authUser.user && authUser.userType === "Nurse") ? (
                      <>
                       <Typography component="div" variant="h5" align="center" sx={{pb:1}}>
                        Your Agenda
                       </Typography>
                       <Grid container spacing={0}>
                       {availableTimeSlotsSaved.length > 0 && !isEditing && (
                        <>
                        <Grid xs={12} md={4} item={true}></Grid>
                        <Grid xs={12} md={4} item={true}>
                            <Box sx={{p:2}}>
                                {availableTimeSlotsSaved.map((value, index)=>
                                    (<DisplayTimeSlots value={value} index={index} removeTimeSlot={removeTimeSlot} readOnly={true}/>)
                                )}
                            </Box>
                            <Typography component="div" align="center" sx={{p:2}}>
                                <Button variant="outlined" startIcon={<ManageHistoryOutlinedIcon />} onClick={()=>{handleEditButtonClick();}} >Add/Edit</Button>
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={4} item={true}></Grid>
                        </>
                       )}
                       {(isEditing || availableTimeSlotsSaved.length == 0) && (
                        <>
                            {(selectedDates == '') && (
                                (availableTimeSlotsSaved.length == 0) ? (
                                    <Grid xs={12} md={4} item={true}></Grid>
                                ) : (
                                    <Grid xs={12} md={4} item={true}>
                                        <Box sx={{p:2}}>
                                            {availableTimeSlotsSaved.map((value, index)=>
                                                (<DisplayTimeSlots value={value} index={index} removeTimeSlot={removeTimeSlot} readOnly={true}/>)
                                            )}
                                        </Box>
                                        <Typography component="div" align="center" sx={{p:2}}>
                                            
                                        </Typography>
                                    </Grid>
                                )
                            )}
                            <Grid xs={12} md={4} item={true}>
                                    <Typography component="div" align="left" sx={{p:2}}>
                                        <FormControl sx={{width: '100%' }}>
                                            <InputLabel id="demo-simple-select-helper-label">Available Date</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={selectedDates}
                                            label="Available Date"
                                            onChange={handleSelectedDatesChange}
                                            >
                                            <MenuItem value={`weekdays`}>Weekdays</MenuItem>
                                            <MenuItem value={`weekends`}>Weekends</MenuItem>
                                            <MenuItem value={`customized`}>Customize</MenuItem>
                                            </Select>
                                        <FormHelperText>{selectedDatesHelperText}</FormHelperText>
                                        </FormControl>
                                        {showPickDates && (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateCalendar
                                                value={value}
                                                onChange={(newValue) => setValue(newValue)}
                                                showDaysOutsideCurrentMonth
                                                displayWeekNumber
                                                slots={{ day: Day }}
                                                slotProps={{
                                                day: (ownerState) => ({
                                                    selectedDay: value,
                                                    hoveredDay,
                                                    onPointerEnter: () => setHoveredDay(ownerState.day),
                                                    onPointerLeave: () => setHoveredDay(null),
                                                }),
                                                }}
                                            />
                                        </LocalizationProvider>
                                        )}
                                        {showPickCustomizedDates && (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateCalendar value={selectedDay} onChange={(newValue) => selectDay(newValue)} showDaysOutsideCurrentMonth displayWeekNumber/>
                                        </LocalizationProvider>
                                        )}
                                    </Typography>
                            </Grid>
                            {(selectedDates == '') && (
                                <Grid xs={12} md={4} item={true}></Grid>
                            )}
                            <Grid xs={12} md={4} item={true}>
                                    {(showPickDates || showPickCustomizedDates) && (
                                        <Typography component="div" align="left" sx={{p:2}}>
                                        <ToggleButtonGroup
                                            value={selectedTimeSlots}
                                            onChange={handleSelectedTimeSlots}
                                            aria-label="Time Slots"
                                            orientation="vertical"
                                            sx={{width: '100%'}}
                                            >
                                                {bookingTimeSlots.map((value, index) => (
                                                    <ToggleButton value={index} aria-label="bold" style={{width: '100%'}}>
                                                    {value}
                                                </ToggleButton>
                                                ))}
                                        </ToggleButtonGroup>
                                        </Typography>
                                    )}
                                    {showPickDates && (
                                        <Typography component="div" align="center" sx={{p:2}}>
                                            <Button variant="outlined" startIcon={<MoreTimeIcon />}
                                            disabled={!selectedTimeSlots.length>0} onClick={addTimeSlots}>
                                                Add
                                            </Button>
                                        </Typography>
                                    )}
                                    {showPickCustomizedDates && (
                                        <Typography component="div" align="center" sx={{p:2}}>
                                            <Button variant="outlined" startIcon={<MoreTimeIcon />}
                                            disabled={!selectedTimeSlots.length>0} onClick={addCustomizedTimeSlots}>
                                                Add
                                            </Button>
                                        </Typography>
                                    )}
                            </Grid>
                            <Grid xs={12} md={4} item={true}>
                            <Box sx={{p:2}}>
                                {availableTimeSlots.map((value, index)=>
                                    (<DisplayTimeSlots value={value} index={index} removeTimeSlot={removeTimeSlot} readOnly={false}/>)
                                )}
                            </Box>
                            </Grid>
                            <Grid xs={12} md={12} item={true}>
                                {(availableTimeSlots.length > 0 || showConfirmCheckBox) && (
                                    <>
                                        <Typography component="div" variant="body2" align="center" sx={{p:2}}>
                                        <Checkbox
                                            checked={agreementChecked}
                                            onChange={handleAgreementCheckChange}
                                            inputProps={{ 'aria-label': 'agreement' }}
                                        /> 
                                        {(availableTimeSlots.length > 0) ? (<>I confirm my availability for the above time slots.</>) : (<>I confirm to remove all time slots.</>)}
                                        </Typography>
                                        <Typography component="div" variant="body2" align="center" sx={{px:2}}>
                                        <LoadingButton
                                            onClick={handleSaveButtonClick}
                                            loading={loading}
                                            loadingPosition="start"
                                            startIcon={<SaveIcon />}
                                            variant="outlined"
                                            disabled={!agreementChecked}
                                        >
                                            <span>Save</span>
                                        </LoadingButton>
                                        </Typography>
                                    </>
                                )}
                            </Grid>
                            <Grid xs={12} md={12} item={true} sx={{bgcolor: '#1f1f1f', color: '#fff', mt: 4, borderRadius: '10px'}}>
                                    <Grid container spacing={0}>
                                        <Grid xs={12} md={4} item={true}>
                                            <Typography component="div" align="left" sx={{p:2}}>
                                                <Typography component="div" align="left" sx={{fontWeight: 'bold'}}>
                                                Step 1
                                                </Typography>
                                                <Typography component="div" align="left">
                                                Pick your available dates.
                                                </Typography>
                                                <Typography component="div" align="left">
                                                    <img src={`https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/webpages-media%2Fdate_picker.png?alt=media&token=f3ca6b1f-8236-444d-80ec-e1547ca35df5`} style={{width: '60%'}}/>
                                                </Typography>
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} md={4} item={true}>
                                            <Typography component="div" align="left" sx={{p:2}}>
                                                <Typography component="div" align="left" sx={{fontWeight: 'bold'}}>
                                                step 2
                                                </Typography>
                                                <Typography component="div" align="left">
                                                Choose the time you're available.
                                                </Typography>
                                                <Typography component="div" align="left">
                                                    <img src={`https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/webpages-media%2Ftimeslots_picker.png?alt=media&token=f512cd05-5267-4060-9a4c-6808724e8500`} style={{width: '60%'}}/>
                                                </Typography>
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} md={4} item={true}>
                                            <Typography component="div" align="left" sx={{p:2}}>
                                                <Typography component="div" align="left" sx={{fontWeight: 'bold'}}>
                                                step 3
                                                </Typography>
                                                <Typography component="div" align="left">
                                                Review and Confirm your available time.
                                                </Typography>
                                                <Typography component="div" align="left">
                                                    <img src={`https://firebasestorage.googleapis.com/v0/b/helical-client-414716.appspot.com/o/webpages-media%2Fcustomized_timeslots.png?alt=media&token=c18765bb-28c2-4109-b400-f900a9a37835`} style={{width: '60%'}}/>
                                                </Typography>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                            </Grid>
                        </>
                        )}
                       </Grid>
                      </>
                     ) : (
                        <>
                        Access Permission Required.
                        </>
                     )}
                    </Box>
                    <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Agenda;
