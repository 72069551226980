import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { useQuery, gql } from '@apollo/client';
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled } from "@mui/material";

const client = new ApolloClient({
  uri: 'https://graph.mintbase.xyz/mainnet',
  cache: new InMemoryCache(),
});

const QUERY_NFT_INFO = gql`
query MyQuery {
  mb_views_nft_tokens(
    where: {owner: {_eq: "monadsocial.near"}, _and: {burned_timestamp: {_is_null: true}, last_transfer_timestamp: {_is_null: false}}}
    limit: 30
    order_by: {last_transfer_timestamp: desc}
  ) {
    nft_contract_id
    title
    description
    media
    last_transfer_receipt_id
  }
}
`;

function Display() {
  const { loading, error, data } = useQuery(QUERY_NFT_INFO, {
  context: { headers: { 'mb-api-key': 'anon' } }
});

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

    console.log('data ', data);
    
  return (
    <div>
        <h1>NFTs</h1>
          {data.mb_views_nft_tokens.map((nft) => (
            <>
             <h3>{nft.title}</h3>
             <img width="100%" alt="NFT" src={`${nft.media}`} />
             <div>{nft.description}</div>
            </>
          ))}
    </div>
  )
}

const NFT = ({loader}) => {
    
    return (
        <>
            <ApolloProvider client={client}>
              <Display />
            </ApolloProvider>
        </>
    )
}

export default NFT;
