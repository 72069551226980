import { AuthContext, FireactContext, SetPageTitle } from "../../lib/core";
import { Alert, Box, Button, Checkbox, Container, FormControl, FormControlLabel, FormLabel, Grid, Paper, TextField, Typography, CardMedia } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
//import "firebase/compat/functions";
import { httpsCallable } from 'firebase/functions';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../Stripe/CheckoutForm";
import {PageSection} from "../../template/TemplateElements";
import Footer from "../../template/Footer";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import LoadingButton from '@mui/lab/LoadingButton';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { checkPermission } from "../../lib/components/utilities";

const stripePromise = loadStripe("pk_test_51L44EYAZ8dJdmsUfoGp1AyJ5Mm6jQYpGlUDKUSPdCptoTAyVBmQ03bwhkM0jIh2sgLHy9e65MeQK6WpqAjp736SO00aV0psDqO");

const SubscriptionHome = ({loader, enableExtensionMenu}) => {

    const { subscriptionId } = useParams();

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);

    const { config } = useContext(FireactContext);
    const permissions = config.saas.permissions || {};

    const [ processing, setProcessing ] = useState(false);
    
    const [ loadingCC, setLoadingCC ] = useState(false);
    const [ showDonateButton, setShowDonateButton ] = useState(true);
    
    const [ loadingTokenMetadata, setLoadingTokenMetadata ] = useState(false);
    
    const [ email, setEmail ] = useState('');
    const [ displayName, setDisplayName ] = useState('');

    const [loaded, setLoaded] = useState(false);
    const [ error, setError ] = useState(null);
    const [ success, setSuccess ] = useState(false);
    const [ subscription, setSubscription ] = useState(null);
    const [ tokenMetadata, setTokenMetadata] = useState(null);

    const [clientSecret, setClientSecret] = useState("");

    const [defaultPermissions, setDefaultPermissions] = useState([]);
    const [adminPermissions, setAdminPermissions] = useState([]);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };
    
    const startDonate = () => {
        setLoadingCC(true);
        const createCheckoutSession = httpsCallable(functionsInstance, 'fireactjsSaas-createCheckoutSession');
        //console.log('createCheckoutSession ', createCheckoutSession);
        createCheckoutSession({ items: [{ id: "xl-tshirt" }] }).then((res) => {
            //console.log('res ', res);
            setClientSecret(res.data.clientSecret);
            setLoadingCC(false);
            setShowDonateButton(false);
        }).catch(error => {
            //setError(error.message);
            //setProcessing(false);
            console.log('error ', error);
        })
    }
    
    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }
        
        const defaultPermissions = [];
        const adminPermissions = [];

        for(let permission in config.saas.permissions){
            if(config.saas.permissions[permission].default){
                defaultPermissions.push(permission);
            }
            if(config.saas.permissions[permission].admin){
                adminPermissions.push(permission);
            }
        }
        
        enableExtensionMenu(null);
        
        const docRef = doc(firestoreInstance, "subscriptions", subscriptionId);

        getDoc(docRef).then(docSnap => {
            if(docSnap.exists()){
                const sub = docSnap.data();
                sub.id = subscriptionId;
                setSubscription(sub);
                
                if (authInstance.currentUser) {
                    const adminPermission = checkPermission(sub, authInstance.currentUser.uid, adminPermissions);
                    if (adminPermission) {
                        setAdminPermissions(adminPermissions);
                        enableExtensionMenu("groupAdmin");
                    }
                    
                    const defaultPermission = checkPermission(sub, authInstance.currentUser.uid, defaultPermissions);
                    if (defaultPermission) {
                        setDefaultPermissions(defaultPermissions);
                        enableExtensionMenu("groupAccess");
                    }
                }
            
                setLoaded(true);
            }else{
                // no subscription
                setLoaded(true);
                enableExtensionMenu(null);
                setError("No "+config.saas.subscription.singular+" matches the ID");
            }
        }).catch(error => {
            setLoaded(true);
            enableExtensionMenu(null);
            setError(error.message);
        });
        //console.log("subscriptionId ", subscriptionId);
    },[authInstance, config.saas.permissions, firestoreInstance]);
    
    return (
    <>
        {loaded?(
         <Container maxWidth={false} disableGutters>
             <SetPageTitle title="OpenTech+" />
              <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
              <Grid container spacing={0}>
                    <Grid xs={12} md={12}>
                      <PageSection>
                        Welcome to {subscription.name}
                      </PageSection>
                    </Grid>
                    <Grid xs={12} md={12}>
                       <Grid container spacing={0}>
                           <Grid xs={12} md={4}>
                               <PageSection>
                 
                               </PageSection>
                           </Grid>
                           <Grid xs={12} md={4}>
                               <PageSection>
                                 
                               </PageSection>
                           </Grid>
                           <Grid xs={12} md={4}>
                             <PageSection>
                                
                             </PageSection>
                           </Grid>
                           <Grid xs={12} md={4}>
                             <PageSection>
                              {showDonateButton && (
                                   <Box component="section" sx={{ p: 2 }}>
                                     <LoadingButton variant="outlined" startIcon={<CardGiftcardIcon />} onClick={() => {
                                         startDonate();
                                     }} loading={loadingCC} loadingPosition="start">
                                       Donate
                                     </LoadingButton>
                                   </Box>
                               )}
                               {clientSecret && (
                                 <Box component="section" sx={{ p: 2, border: '1px dashed grey' }}>
                                     <Elements options={options} stripe={stripePromise}>
                                         <CheckoutForm />
                                     </Elements>
                                 </Box>
                               )}
                             </PageSection>
                           </Grid>
                       </Grid>
                    </Grid>
                  </Grid>
             </Box>
             <Footer />
         </Container>
        ):(
            <>{loader}</>
        )}
    </>
    )
}

export default SubscriptionHome;
