import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../Auth";
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from "react-router-dom";
import { SetPageTitle } from "../SetPageTitle";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { FireactContext } from "../Fireact";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import Link from '@mui/material/Link';

// https://firebase.google.com/docs/storage/web/start
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, GeoPoint } from 'firebase/firestore';
import Footer from "../../../../template/Footer";
import {PageSection} from "../../../../template/TemplateElements";
import {APIProvider, Map, Marker, useMapsLibrary, useMap, createMarker} from '@vis.gl/react-google-maps';
import {QueryAddress, PlaceAutocomplete} from "../../../../components/google_maps/MapComponents";
import * as geofire from 'geofire-common';

export const UserAccount = ({loader}) => {
    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    
    //console.log('authUser ', authUser);

    //Vancouver 49.2827291 -123.1207375
    const [position, setPosition] = useState({lat: 49.2827291, lng: -123.1207375});
    const setMarker = (lat, lng) => {
      setPosition({lat: lat, lng: lng});
    }

    const [myAddress, setMyAddress] = useState(null);
    const [addressEntered, setAddressEntered] = useState('');
    const currentMyAddress = useRef(null);
    const [clear, setClear] = useState(false);

    useEffect(() => {
        currentMyAddress.current = myAddress;
        //console.log('currentMyAddress.current', myAddress);
    }, [myAddress]);
    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        const placesRef = collection(firestoreInstance, '/places');
        const placeQuery = query(placesRef, where('users', 'array-contains', authUser.user.uid));

        Promise.all([getDocs(placeQuery)]).then(([placeSnapshot]) => {
            placeSnapshot.forEach(record => {
                const address = record.data();
                setPosition({lat: address.lat, lng: address.lng});
                setMyAddress({...address, ...{id:record.id}});
                //console.log("my address ", address, record.id);
            });
            setLoaded(true);
        }).catch(error => {
            setLoaded(true);
            setError(error.message);
        });

        const getExistingUserServiceAccount = async () => {
            //get existing user service account
            const docRef = doc(firestoreInstance, "userServiceAccounts", authUser.user.uid);
            await getDoc(docRef).then(docSnap => {
                const data = docSnap.data();
                //console.log("Document data:", data);
                if (data.name) {
                    setName(data.name);
                }
                if (data.email) {
                    setEmail(data.email);
                }
                if (data.phone) {
                    setPhone(data.phone);
                }
                if (data.license) {
                    setLicense(data.license);
                }
            }).catch(err => {
                //
            });
        }
        
        getExistingUserServiceAccount();
    },[authInstance, config.saas.permissions, firestoreInstance]);

    const addAddress = async () => {
        const placesRef = collection(firestoreInstance, '/places');
        const placeQuery = query(placesRef, where('address', '==', addressEntered));

        var addressAdded = false;

        //1. check if addressEntered is already in DB, if not add new address
        const querySnapshot = await getDocs(placeQuery);
        querySnapshot.forEach((record) => {
            const address = record.data();
            addressAdded = true;
            //2. add user to address
            const placeRef = doc(firestoreInstance, '/places', record.id);
            const currentUsers = address.users ? address.users : [];
            currentUsers.push(authUser.user.uid);
            setDoc(placeRef, { users: currentUsers }, { merge: true });
            setMyAddress({...address, ...{id:record.id}});
            console.log("existing data ", address);
        });

        if (!addressAdded) {
            // Compute the GeoHash for a lat/lng point
            const hash = geofire.geohashForLocation([position.lat, position.lng]);
            
            // Add the hash and the lat/lng to the document. We will use the hash
            // for queries and the lat/lng for distance comparisons.
            
            const docRef = addDoc(placesRef, {
                address: addressEntered,
                geohash: hash,
                lat: position.lat,
                lng: position.lng,
                position: new GeoPoint(position.lat, position.lng),
                users: [authUser.user.uid]
              }).then((docRef) => {
                getDoc(docRef).then(docSnap => {
                    const address = docSnap.data();
                    setMyAddress({...address, ...{id:docSnap.id}});
                    //console.log("place added with ID: ", docSnap.id);
                  }).catch(err => {
                    //
                  });
              });
        }
    }

    const updateAddress = async () => {
        //1. remove old address if there's any
        //console.log('logged in user', authUser.user.uid);
        //console.log('currentMyAddress.current', currentMyAddress.current);
        if (currentMyAddress.current.users.includes(authUser.user.uid)) {
            const users = currentMyAddress.current.users;
            const index = users.indexOf(authUser.user.uid);
            if (index > -1) { // only splice array when item is found
                users.splice(index, 1); 
            }
            //console.log('my address new users ', users);
            const docRef = doc(firestoreInstance, "places", currentMyAddress.current.id);
            setDoc(docRef, { users: users }, { merge: true });
        }
        //2. add new address
        addAddress();
        setAddressEntered('');
        setClear(true);
    }

    const [tabValue, setTabValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [accountSetupCompleted, setAccountSetupCompleted] = React.useState(false);
    const steps = [
        'Account Settings',
        'License Verification',
        'Service Area',
        'Add Treatments',
    ];
    // when adding treatments, only add uid and pid to services doc
    // createTreatmentService function will process the rest

    const [activeStep, setActiveStep] = React.useState(0); // read from DB
    const [loadingStep1, setLoadingStep1] = React.useState(false);
    const ref = React.useRef(null);
    useEffect(() => {
        if (activeStep == 4) {
            setAccountSetupCompleted(true);
        }
        if (ref.current) {
            //console.log('scroll', ref.current);
            //ref.current.scrollIntoView();
            ref.current.parentNode.scrollTop = 0;
            //ref.current.ownerDocument.body.scrollTop = 0;
        }
    }, [activeStep]);

    const [invalidPhoneNumber, setInvalidPhoneNumber] = React.useState(false);
    const [phone, setPhone] = React.useState('');
    const handlePhoneNumberChange = (newPhone) => {
        setPhone(newPhone);
    };

    const [email, setEmail] = useState(authUser.user.email);
    const [emailError, setEmailError] = useState(false);
    const handleEmailChange = e => {
        setEmail(e.target.value);
        if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(e.target.value)) {
          setEmailError(true);
        } else {
          setEmailError(false);
        }
    };

    const [name, setName] = useState(authUser.user.displayName);
    const [nameError, setNameError] = useState(false);
    const [nameHelperText, setNameHelperText] = useState('Service name to be used on Living Waters');
    const handleNameChange = e => {
        setName(e.target.value);
        if (e.target.value.length < 3) {
            setNameHelperText("Name must be at least 3 characters long");
            setNameError(true);
        } else if (e.target.value.length > 20) {
            setNameHelperText("Name must be less than 20 characters long");
            setNameError(true);
        } else if (!/^[a-zA-Z ]+$/.test(e.target.value)) {
            setNameHelperText("Name must contain only letters and spaces");
            setNameError(true);
        } else {
          setNameError(false);
          setNameHelperText("Service name to be used on Living Waters");
        }
    };

    const saveServiceInfo = async () => {
        //console.log('save service info', phone);
        const isValidatePhonenumber = matchIsValidTel(phone);
        setInvalidPhoneNumber(!isValidatePhonenumber);
        //setInvalidPhoneNumber(true);
        if (isValidatePhonenumber && !emailError && !nameError) {

            const docRef = doc(firestoreInstance, "userServiceAccounts", authUser.user.uid);
            await setDoc(docRef, {
                name: name,
                email: email,
                phone: phone
            },{merge: true}).then(() => {
                setActiveStep(1);
            }).catch((error) => {
              //
            });
        }
        //console.log('service info', name, email, phone);
    };

    const [license, setLicense] = useState('');
    const handleLicenseChange = e => {
        setLicense(e.target.value);
    };

    const saveLicenseInfo = async () => {
        const docRef = doc(firestoreInstance, "userServiceAccounts", authUser.user.uid);
        await setDoc(docRef, {
            license: license
        },{merge: true}).then(() => {
            setActiveStep(2);
        }).catch((error) => {
            //
        });
        //console.log('license info', license);
    };

    const saveAddressInfo = () => {
        if (myAddress) {
            setActiveStep(3);
        }
    }

    return (
        <div ref={ref}>
            {loaded?(
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title="Account" />
                    <Box sx={{ p: 2, width: '100%'}}>
                        <Typography component="h6" variant="h6">
                        {authUser.user  !== null &&
                            <>{accountSetupCompleted ? ("Account") : ("Set up service account")}</>
                        }
                        {authUser.user  === null &&
                            <>Welcome to Living Waters</>
                        }
                        </Typography>

                    </Box>
                    {accountSetupCompleted ? (
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={tabValue}  variant="scrollable" scrollButtons="auto" >
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="Dashboard" variant="scrollable" allowScrollButtonsMobile scrollButtons="auto">
                                <Tab label="Account Settings" value="1" />
                                <Tab label="License" value="2" />
                                <Tab label="Service Area" value="3" />
                                <Tab label="Treatments" value="4" />
                            </TabList>
                            </Box>
                            <TabPanel value="1">
                            <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                                <Grid container spacing={0}>
                                    <Grid xs={12} md={12}>
                                        <PageSection>
                                            <Typography variant="body1" align="left" gutterBottom>
                                            Name, email, phone, banking info, emergency contact 
                                            </Typography>
                                        </PageSection>
                                    </Grid>
                                </Grid>
                            </Box>
                            </TabPanel>
                            <TabPanel value="2">
                            <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                                <Grid container spacing={0}>
                                    <Grid xs={12} md={12}>
                                        <PageSection>
                                            <Typography variant="body1" align="left" gutterBottom>
                                            license #, License Verification: Search state license base and verify (or manual ?) 
                                            </Typography>
                                        </PageSection>
                                    </Grid>
                                </Grid>
                            </Box>
                            </TabPanel>
                            <TabPanel value="3">
                            <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                            <APIProvider apiKey={'AIzaSyAmKhNjAcR53nVKJ2a3KOJi8iRgjBLYX4Q'}>
                                <Grid container spacing={0}>
                                    <Grid xs={12} md={6}>
                                        <PageSection sx={{height: '100%'}}>
                                            <Grid container spacing={0}>
                                                <Grid xs={12} md={12}>
                                                    {myAddress ? (
                                                    <>
                                                        <Typography component="h6" variant="h6" align="left">
                                                            My Address
                                                        </Typography>
                                                        <Typography variant="body1" align="left" gutterBottom>
                                                            {addressEntered ? (
                                                                <>
                                                                <Grid container spacing={0}>
                                                                    <Grid xs={12}>
                                                                        {addressEntered}
                                                                    </Grid>
                                                                    <Grid xs={12}>
                                                                    <Stack direction="row" spacing={2}>
                                                                        <Button onClick={updateAddress} variant="contained">Change Address</Button>
                                                                        <Button onClick={() => {setAddressEntered('')}} variant="outlined">Cancel</Button>
                                                                    </Stack>
                                                                    </Grid>
                                                                </Grid>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Typography variant="body1" align="left" gutterBottom>{myAddress.address}</Typography>
                                                                    <Typography fontStyle="italic" variant="caption" align="left" gutterBottom>Enter a new location to change your address</Typography>
                                                                </>
                                                            )}
                                                        </Typography>
                                                    </>
                                                    ) : (
                                                    <>
                                                        <Typography component="h6" variant="h6" align="left">
                                                            My Address
                                                        </Typography>
                                                        <Typography variant="body1" align="left" gutterBottom>
                                                            {addressEntered ? (
                                                                    <>
                                                                    <Grid container spacing={0}>
                                                                        <Grid xs={12}>
                                                                            {addressEntered}
                                                                        </Grid>
                                                                        <Grid xs={12}>
                                                                            <Button onClick={addAddress} variant="contained">Add Address</Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                    </>
                                                                ) : (
                                                                    <>You haven't entered an address for your service yet.<br />Please enter your address.</>
                                                                )}
                                                        </Typography>
                                                    </>
                                                    )} 
                                                </Grid>
                                                <Grid xs={12} md={12}>
                                                    <PlaceAutocomplete setMarker={setMarker} showCircle={false} setAddressEntered={setAddressEntered} label={`Enter My Address`} clear={clear} setClear={setClear}/>
                                                </Grid>
                                            </Grid>
                                        </PageSection>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <PageSection sx={{minHeight: '400px', height: '100%'}}>
                                            <Grid container spacing={0}>
                                                <Grid xs={12} md={12}>
                                                <Box sx={{minHeight: '400px', height: '100%'}}>
                                                    <Map center={position} zoom={10} disableDefaultUI={true} scrollwheel={false} gestureHandling={'none'} keyboardShortcuts={false}>
                                                    <Marker position={position} />
                                                    </Map>
                                                    {/*<QueryAddress setMarker={setMarker} currentCircle={currentCircle} />*/}
                                                </Box>
                                                </Grid>
                                            </Grid>
                                        </PageSection>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <PageSection>
                                            
                                        </PageSection>
                                    </Grid>
                                    <Grid xs={12} md={12}>
                                        <PageSection>
                                            
                                        </PageSection>
                                    </Grid>
                                </Grid>
                            </APIProvider>
                            </Box>
                            </TabPanel>
                            <TabPanel value="4">
                            <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                                <Grid container spacing={0}>
                                    <Grid xs={12} md={12}>
                                        <PageSection>
                                            <Typography variant="body1" align="left" gutterBottom>
                                                Purchase: Set-up account via Amazon to purchase IV Treatments
                                            </Typography>
                                        </PageSection>
                                    </Grid>
                                </Grid>
                            </Box>
                            </TabPanel>
                        </TabContext>
                    </Box>
                    ) : (
                    <PageSection sx={{height: '100%'}}>
                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                            </Stepper>
                        </Box>
                        {activeStep == 0 && (
                        <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                            <Grid container spacing={0}>
                                <Grid xs={12} md={4}>
                                    <PageSection>
                                        <Typography variant="body2" align="left" style={{fontWeight: 'bold'}} gutterBottom>
                                            Service Information
                                        </Typography>
                                        <Typography variant="body1" align="left" gutterBottom>
                                            <TextField sx={{width: '100%'}}
                                                helperText={nameHelperText}
                                                id="name-helper-text-aligned"
                                                label="Service Name"
                                                required
                                                value={name}
                                                onChange={handleNameChange}
                                                error={nameError}
                                            />
                                        </Typography>
                                        <Typography variant="body1" align="left" gutterBottom>
                                            <TextField sx={{width: '100%'}}
                                                helperText="Can be different to your user account email"
                                                id="email-helper-text-aligned"
                                                label="Service Email"
                                                required
                                                value={email}
                                                onChange={handleEmailChange}
                                                error={emailError}
                                            />
                                        </Typography>
                                        <Typography variant="body1" align="left" gutterBottom>
                                            <MuiTelInput sx={{width: '100%'}}
                                                helperText="This phone number is only used for your service"
                                                id="phone-helper-text-aligned"
                                                label="Service Phone Number"
                                                required
                                                error={invalidPhoneNumber}
                                                value={phone} onChange={handlePhoneNumberChange}
                                                defaultCountry='US'
                                            />
                                        </Typography>
                                    </PageSection>
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <PageSection>
                                        <Typography variant="body2" align="left" style={{fontWeight: 'bold'}} gutterBottom>
                                            Payroll Information
                                        </Typography>
                                        <Typography variant="body1" align="left" gutterBottom>
                                            <TextField sx={{width: '100%'}}
                                                helperText="Please enter your bank info"
                                                id="bank-info-helper-text-aligned"
                                                label="Bank Info"
                                            />
                                        </Typography>
                                    </PageSection>
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <PageSection>
                                        <Typography variant="body2" align="left" style={{fontWeight: 'bold'}} gutterBottom>
                                            Emergencey Contact
                                        </Typography>
                                        <Typography variant="body1" align="left" gutterBottom>
                                            <TextField sx={{width: '100%'}}
                                                helperText="Please enter your emergency contact"
                                                id="emergency-contact-helper-text-aligned"
                                                label="Emergencey Contact"
                                            />
                                        </Typography>
                                    </PageSection>
                                </Grid>
                            </Grid>
                            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                            <Typography variant="body1" align="center" sx={{p:2}} gutterBottom>
                                <LoadingButton
                                    loading = {loadingStep1}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    onClick={() => {
                                        saveServiceInfo();
                                    }}
                                >
                                    Save
                                </LoadingButton>
                            </Typography>
                            </Stack>
                        </Box>
                        )}
                        {activeStep == 1 && (
                        <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                            <Grid container spacing={0}>
                                <Grid xs={12} md={12}>
                                    <PageSection>
                                        <Typography variant="body1" align="left" gutterBottom>
                                            <TextField sx={{width: '100%'}}
                                                helperText="Please enter your NCSBN ID"
                                                id="icense-info-helper-text-aligned"
                                                label="License"
                                                onChange={handleLicenseChange}
                                                defaultValue={license}
                                            />
                                        </Typography>
                                        <Typography variant="body2" align="left" sx={{fontSize:10, mt:1}} gutterBottom>
                                        Customer can validate at <Link href="https://www.nursys.com/LQC/LQCSearch.aspx" target="_blank">https://www.nursys.com/LQC/LQCSearch.aspx</Link>
                                        </Typography>
                                    </PageSection>
                                </Grid>
                            </Grid>
                            <Typography variant="body1" align="center" sx={{p:2}} gutterBottom>
                            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                                <Button variant="outlined" onClick={() => {
                                        setActiveStep(0);
                                    }}>Previous</Button>
                                <LoadingButton
                                    loading = {loadingStep1}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    onClick={() => {
                                        saveLicenseInfo();
                                    }}
                                >
                                    Continue
                                </LoadingButton>
                            </Stack>
                            </Typography>
                        </Box>
                        )}
                        {activeStep == 2 && (
                        <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                            <Grid container spacing={0}>
                                <Grid xs={12} md={12}>
                                    <APIProvider apiKey={'AIzaSyAmKhNjAcR53nVKJ2a3KOJi8iRgjBLYX4Q'}>
                                        <Grid container spacing={0}>
                                            <Grid xs={12} md={6}>
                                                <PageSection sx={{height: '100%'}}>
                                                    <Grid container spacing={0}>
                                                        <Grid xs={12} md={12}>
                                                            {myAddress ? (
                                                            <>
                                                                <Typography component="h6" variant="h6" align="left">
                                                                    My Address
                                                                </Typography>
                                                                <Typography variant="body1" align="left" gutterBottom>
                                                                    {addressEntered ? (
                                                                        <>
                                                                        <Grid container spacing={0}>
                                                                            <Grid xs={12}>
                                                                                {addressEntered}
                                                                            </Grid>
                                                                            <Grid xs={12}>
                                                                            <Stack direction="row" spacing={2}>
                                                                                <Button onClick={updateAddress} variant="contained">Change Address</Button>
                                                                                <Button onClick={() => {setAddressEntered('')}} variant="outlined">Cancel</Button>
                                                                            </Stack>
                                                                            </Grid>
                                                                        </Grid>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Typography variant="body1" align="left" gutterBottom>{myAddress.address}</Typography>
                                                                            <Typography fontStyle="italic" variant="caption" align="left" gutterBottom>Enter a new location to change your address</Typography>
                                                                        </>
                                                                    )}
                                                                </Typography>
                                                            </>
                                                            ) : (
                                                            <>
                                                                <Typography component="h6" variant="h6" align="left">
                                                                    My Address
                                                                </Typography>
                                                                <Typography variant="body1" align="left" gutterBottom>
                                                                    {addressEntered ? (
                                                                            <>
                                                                            <Grid container spacing={0}>
                                                                                <Grid xs={12}>
                                                                                    {addressEntered}
                                                                                </Grid>
                                                                                <Grid xs={12}>
                                                                                    <Button onClick={addAddress} variant="contained">Add Address</Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                            </>
                                                                        ) : (
                                                                            <>You haven't entered an address for your service yet.<br />Please enter your address.</>
                                                                        )}
                                                                </Typography>
                                                            </>
                                                            )} 
                                                        </Grid>
                                                        <Grid xs={12} md={12}>
                                                            <PlaceAutocomplete setMarker={setMarker} showCircle={false} setAddressEntered={setAddressEntered} label={`Enter My Address`} clear={clear} setClear={setClear}/>
                                                        </Grid>
                                                    </Grid>
                                                </PageSection>
                                            </Grid>
                                            <Grid xs={12} md={6}>
                                                <PageSection sx={{minHeight: '400px', height: '100%'}}>
                                                    <Grid container spacing={0}>
                                                        <Grid xs={12} md={12}>
                                                        <Box sx={{minHeight: '400px', height: '100%'}}>
                                                            <Map center={position} zoom={10} disableDefaultUI={true} scrollwheel={false} gestureHandling={'none'} keyboardShortcuts={false}>
                                                            <Marker position={position} />
                                                            </Map>
                                                            {/*<QueryAddress setMarker={setMarker} currentCircle={currentCircle} />*/}
                                                        </Box>
                                                        </Grid>
                                                    </Grid>
                                                </PageSection>
                                            </Grid>
                                        </Grid>
                                    </APIProvider>
                                </Grid>
                            </Grid>
                            <Typography variant="body1" align="center" sx={{p:2}} gutterBottom>
                            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                                <Button variant="outlined"  onClick={() => {
                                        setActiveStep(1);
                                    }}>Previous</Button>
                                <LoadingButton
                                    loading = {loadingStep1}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    onClick={() => {
                                        saveAddressInfo();
                                    }}
                                    disabled={!myAddress}
                                >
                                    Continue
                                </LoadingButton>
                            </Stack>
                            </Typography>
                        </Box>
                        )}
                        {activeStep == 3 && (
                        <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                            <Grid container spacing={0}>
                                <Grid xs={12} md={12}>
                                    <PageSection>
                                        <Typography variant="body1" align="left" gutterBottom>
                                            Purchase: Set-up account via Amazon to purchase IV Treatments
                                        </Typography>
                                    </PageSection>
                                </Grid>
                            </Grid>
                            <Typography variant="body1" align="center" sx={{p:2}} gutterBottom>
                            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
                                <Button variant="outlined"  onClick={() => {
                                        setActiveStep(2);
                                    }}>Previous</Button>
                                <LoadingButton
                                    loading = {loadingStep1}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    onClick={() => {
                                        setActiveStep(4);
                                    }}
                                >
                                    Complete
                                </LoadingButton>
                            </Stack>
                            </Typography>
                        </Box>
                        )}
                    </PageSection>
                    )}
                    <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </div>
    )
}