import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import ImagesDropzone from "./imagesDropzone";
import ImageElement from "./imageElement";

// https://firebase.google.com/docs/storage/web/start
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc } from 'firebase/firestore';

const Uploader = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const [imageList, setImageList] = useState([]);
    //console.log('authUser ', authUser);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }
        // read images from firestore DB
        // add this route to authroutes.json to require logging in
        const imagesRef = collection(firestoreInstance, '/images');
        const imgQuery = query(imagesRef, where('uid', '==', authInstance.currentUser.uid));
        
        const storage = getStorage();
        // Create a child reference
        const imagesStorageRef = ref(storage, 'images');
        
        // all images
        //Promise.all([getDocs(imagesRef)]).then(([imgSnapshot]) => {
        // images uploaded by uid
        Promise.all([getDocs(imgQuery)]).then(([imgSnapshot]) => {
            setImageList([]);
            imgSnapshot.forEach(record => {
                const data = record.data();
                const newImage = {
                    file: null,
                    fileName: data.filename,
                    status: "FINISH",
                    storageRef: ref(imagesStorageRef, data.filename),
                    downloadURL: data.url,
                    description: "",
                };
                //console.log("newImage ", newImage);
                setImageList((prevState) => [...prevState, newImage]);
            });
            setLoaded(true);
        }).catch(error => {
            setLoaded(true);
            setError(error.message);
        })
    },[authInstance, config.saas.permissions, firestoreInstance]);

    const changeImageField = (index, parameter, value) => {
       const newArray = [...imageList];
       newArray[index][parameter] = value;
       setImageList(newArray);
    };
    
    const handleDeleteImage = (index) => {
        // Delete the file
        deleteObject(imageList[index].storageRef).then(async () => {
            //console.log("delet image ", imageList[index]);
          // File deleted successfully
            const newArray = [...imageList];
            newArray.splice(index, 1);
            setImageList(newArray);
            //delete from firestore DB
            await deleteDoc(doc(firestoreInstance, "images", imageList[index].fileName));
        }).catch((error) => {
          // Uh-oh, an error occurred!
            console.log("Error deleting file:", error);
        });
    };
    
    const handleChangeOrderUp = (index) => {
       // If first, ignore
       if (index !== 0) {
          const newArray = [...imageList];
          const intermediate = newArray[index - 1];
          newArray[index - 1] = newArray[index];
          newArray[index] = intermediate;
          setImageList(newArray);
       }
    };

    const handleChangeOrderDown = (index) => {
       // If last, ignore
       if (index < imageList.length - 1) {
          const newArray = [...imageList];
          const intermediate = newArray[index + 1];
          newArray[index + 1] = newArray[index];
          newArray[index] = intermediate;
          setImageList(newArray);
       }
    };

    useEffect(() => {
       imageList.forEach((image, index) => {
          //console.log('image ', image);
          if (image.status === "FINISH" || image.status === "UPLOADING") return;
          changeImageField(index, "status", "UPLOADING");
          //const uploadTask = image.storageRef.put(image.file);
          const uploadTask = uploadBytesResumable(image.storageRef, image.file);
          uploadTask.on('state_changed',
             (snapshot) => {
               // Observe state change events such as progress, pause, and resume
               // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
               const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
               console.log('Upload is ' + progress + '% done');
               switch (snapshot.state) {
                 case 'paused':
                   console.log('Upload is paused');
                   break;
                 case 'running':
                   console.log('Upload is running');
                   break;
               }
             },
             (error) => {
               // Handle unsuccessful uploads
             },
             () => {
                 // Handle successful uploads on complete
                 getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                     await setDoc(doc(firestoreInstance, "images", image.fileName), {
                         filename: image.fileName,
                         url: downloadURL,
                         uid: authUser.user.uid
                     });
                     changeImageField(index, "downloadURL", downloadURL);
                     changeImageField(index, "status", "FINISH");
                 });
             }
          );
       });
    });


    return (
        <>
            {loaded?(
                <Container maxWidth="lx">
                    <SetPageTitle title="My Subscriptions" />
                    <Paper>
                        <Box p={2}>
                             <Typography component="h1" variant="h4">
                              {authUser.user  !== null &&
                                  <div>Hello {authUser.user.displayName}</div>
                              }
                              {authUser.user  === null &&
                                  <div>Welcome to OpenTech+</div>
                              }
                             </Typography>
                             <Grid container direction="column" alignItems="center" spacing={2}>
                                      <Box border={1} margin={4} padding={3}>
                                         <Grid
                                            item
                                            container
                                            direction="column"
                                            alignItems="center"
                                            xs={12}
                                            spacing={1}
                                         >
                                            <Grid item container xs={12} justify="center">
                                               <ImagesDropzone setImageList={setImageList} />
                                            </Grid>
                                         </Grid>
                                      </Box>
                                      {imageList.length > 0 && (
                                       <>
                                        <Box sx={{ width: '100%', maxHeight: 450, overflowY: 'scroll' }}>
                                          <ImageList variant="masonry" cols={3} gap={8}>
                                            {imageList.map((item) => (
                                              <ImageListItem key={item.downloadURL}>
                                                <img
                                                  srcSet={`${item.downloadURL}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                  src={`${item.downloadURL}?w=248&fit=crop&auto=format`}
                                                  alt={item.title}
                                                  loading="lazy"
                                                />
                                              </ImageListItem>
                                            ))}
                                          </ImageList>
                                        </Box>
                                        <Box p={1}>
                                            {imageList.map((image, index) => {
                                               return (
                                                  <Grid item key={image.fileName + index}>
                                                       <ImageElement
                                                          image={image}
                                                          index={index}
                                                          isFirstElement={index === 0}
                                                          isLastElement={index === imageList.length - 1}
                                                          handleChangeOrderUp={handleChangeOrderUp}
                                                          handleChangeOrderDown={handleChangeOrderDown}
                                                          handleDeleteImage={handleDeleteImage}
                                                          changeImageField={changeImageField}
                                                       />
                                                  </Grid>
                                               );
                                            })}
                                        </Box>
                                       </>
                                      )}
                              </Grid>
                        </Box>
                    </Paper>
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Uploader;
