import { AuthContext, FireactContext, SetPageTitle } from "../core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, TablePagination } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import Footer from "../../template/Footer";
import { PaginationTable } from "./PaginationTable";

export const ListSubscriptions = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [subscriptions, setSubscriptions] = useState([]);
    const [publicSubscriptions, setPublicSubscriptions] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [invites, setInvites] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [acceptedInviteCount, setAcceptedInviteCount] = useState(0);
    
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }
        let subscriptions = [];
        let publicSubscriptions = [];
        let invites = [];

        //const subscriptionsRef = firebaseApp.firestore().collection('subscriptions');
        const subscriptionsRef = collection(firestoreInstance, '/subscriptions');
        //const subQuery = subscriptionsRef.where('permissions.'+defaultPermission, 'array-contains', firebaseApp.auth().currentUser.uid);
        
        const publicSubQuery = query(subscriptionsRef, where('subscriptionStatus', '==', 'active'));
        
        Promise.all([getDocs(publicSubQuery)]).then(([subSnapshot]) => {
            subSnapshot.forEach(record => {
                publicSubscriptions.push({
                    id: record.id,
                    name: record.data().name
                });
            })
            setPublicSubscriptions(publicSubscriptions);
            setTotalRows(publicSubscriptions.length);
            //console.log("publicSubscriptions length ", publicSubscriptions.length);
            setLoaded(true);
        }).catch(error => {
            setLoaded(true);
            setError(error.message);
        })
    
        if (authInstance.currentUser) {
            //const invitesRef = firebaseApp.firestore().collection('invites');
            const invitesRef = collection(firestoreInstance, '/invites');
            //const inviteQuery = invitesRef.where('email', '==', firebaseApp.auth().currentUser.email);
            const inviteQuery = query(invitesRef, where('email', '==', authInstance.currentUser.email));
            
            const subQuery = query(subscriptionsRef, where('permissions.'+defaultPermission, 'array-contains', authInstance.currentUser.uid));
            
            Promise.all([getDocs(subQuery), getDocs(inviteQuery)]).then(([subSnapshot, inSnapshot]) => {
                subSnapshot.forEach(record => {
                    subscriptions.push({
                        id: record.id,
                        name: record.data().name
                    });
                })
                inSnapshot.forEach(record => {
                    invites.push({
                        id: record.id,
                        sender: record.data().sender,
                        subscriptionName: record.data().subscriptionName || "Untitled"
                    })
                })
                setSubscriptions(subscriptions);
                setInvites(invites);
                setLoaded(true);
            }).catch(error => {
                setLoaded(true);
                setError(error.message);
            })
        } else {
            setLoaded(true);
        }
    },[authInstance, config.saas.permissions, acceptedInviteCount, firestoreInstance]);
    
    useEffect(() => {
        const startIndex = page * pageSize;
        let records = [];
        for(let i=startIndex; i<publicSubscriptions.length; i++){
            const sub = publicSubscriptions[i];
            if(i>=startIndex+pageSize){
                break;
            }
            
            records.push(sub);
        }
        if(records.length > 0){
            setRows(records);
        }
        
        //console.log('update page',page, pageSize, records);
    },[page, pageSize, publicSubscriptions]);

    return (
        <>
            {loaded? (
                <Container maxWidth={false} disableGutters>
                     <SetPageTitle title={`${config.saas.subscription.plural}`} />
                     <Box  sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}}>
                         {authInstance.currentUser && (
                            <Box>
                                <Box p={2}>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography component="h5" variant="h6">My {config.saas.subscription.plural}</Typography>
                                        </Grid>
                                        <Grid item textAlign="right">
                                            <Button variant="contained" onClick={() => navigate(config.pathnames.CreateSubscription)}>Create {config.saas.subscription.singular}</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box p={0}>
                                    {error !== null?(
                                        <Alert severity="error">{error}</Alert>
                                    ):(
                                        <>
                                            {invites.length > 0 && authInstance.currentUser.emailVerified &&
                                                <Stack spacing={2} mb={2}>
                                                    {invites.map((invite, i) =>
                                                        <Alert key={i} severity="info" action={
                                                            <>
                                                                <Button color="success" disabled={processing} size="small" onClick={() => {
                                                                    setProcessing(true);
                                                                    const acceptInvite = httpsCallable(functionsInstance, 'fireactjsSaas-acceptInvite');
                                                                    acceptInvite({inviteId: invite.id}).then(() => {
                                                                        setProcessing(false);
                                                                        setAcceptedInviteCount(prevState => (prevState+1));
                                                                    }).catch(error => {
                                                                        // something went wrong
                                                                        setProcessing(false);
                                                                    })
                                                                }}>Accept</Button>
                                                                <Button color="warning" disabled={processing} size="small" onClick={() => {
                                                                    setProcessing(true);
                                                                    const docRef = doc(firestoreInstance, 'invites/'+invite.id);
                                                                    const inviteRef = getDoc(docRef);
                                                                    inviteRef.delete().then(() => {
                                                                        setInvites(prevState =>  prevState.filter(row => {
                                                                            return (row.id !== invite.id)
                                                                        }));
                                                                        setProcessing(false);
                                                                    }).catch(error => {
                                                                        // something went wrong
                                                                        setProcessing(false);
                                                                    })
                                                                }}>Reject</Button>
                                                            </>
                                                        }>
                                                            You are invited to join <strong>{invite.subscriptionName}</strong> by <strong>{invite.sender}</strong>
                                                        </Alert>
                                                    )}
                                                </Stack>
                                            }
                                            {invites.length > 0 && !authInstance.currentUser.emailVerified &&
                                                <Stack spacing={2} mb={2}>
                                                    <Alert severity="warning" action={<Button size="small" onClick={() => navigate(config.pathnames.UserProfile)} >My Profile</Button>}>
                                                        You have invites but your email is not verified. Please go to your profile and verify your email to accept the invites.
                                                    </Alert>
                                                </Stack>
                                            }
                                            <Grid container spacing={0}>
                                                {subscriptions.length>0?(subscriptions.map((subscription, i) =>
                                                    <Grid item xs={12} md={4} key={i}>
                                                        <Card sx={{ margin: "10px" }}>
                                                            <CardHeader title={subscription.name?subscription.name:"Untitled"} subheader={subscription.id} />
                                                            <CardActions>
                                                                <Button variant="outlined" color="success" onClick={() => {
                                                                    navigate(config.pathnames.Subscription.replace(":subscriptionId", subscription.id));
                                                                }}>Access</Button>
                                                            </CardActions>
                                                        </Card>
                                                    </Grid>
                                                )):(
                                                    <Grid item >
                                                        You don't have access to any {config.saas.subscription.singular}. Please create one or ask your admin to invite you to one.
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                </Box>
                             </Box>
                         )}
                         <Box>
                              <Box p={2}>
                                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                      <Grid item>
                                          <Typography component="h5" variant="h6">All {config.saas.subscription.plural}</Typography>
                                      </Grid>
                                      <Grid item textAlign="right">
                                      <Typography component="subtitle1" variant="subtitle2">All {config.saas.subscription.plural}</Typography>
                                      </Grid>
                                  </Grid>
                              </Box>
                              <Box p={0}>
                                  <Grid container spacing={0}>
                                      {rows.length>0?(rows.map((subscription, i) =>
                                          <Grid item xs={12} md={4} key={i}>
                                              <Card sx={{ margin: "10px" }}>
                                               <NavLink to={config.pathnames.Subscription.replace(":subscriptionId", subscription.id)} style={{color: 'inherit', textDecoration:'none'}} key="listusers">
                                                  <CardHeader title={subscription.name?subscription.name:"Untitled"} subheader={subscription.id} />
                                               </NavLink>
                                                  <CardActions>
                                                      
                                                  </CardActions>
                                              </Card>
                                          </Grid>
                                          )
                                      ):(
                                          <Grid item >
                                              No public Group is active.
                                          </Grid>
                                      )}
                                  </Grid>
                                  <Grid container spacing={0}>
                                    <Grid item >
                                      <TablePagination
                                          labelRowsPerPage='Groups per page:'
                                          rowsPerPageOptions={[10,20,50,100]}
                                          component="div"
                                          count={totalRows}
                                          rowsPerPage={pageSize}
                                          page={page}
                                          onPageChange={(e, p) => {
                                              setPage(p);
                                          }}
                                          onRowsPerPageChange={(e) => {
                                              setPage(0);
                                              setPageSize(e.target.value);
                                          }}
                                      />
                                    </Grid>
                                  </Grid>
                              </Box>
                         </Box>
                     </Box>
                     <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}
