import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

// https://firebase.google.com/docs/storage/web/start
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, query, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc } from 'firebase/firestore';

const Stats = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    
    //console.log('authUser ', authUser);

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        // read images from firestore DB
        // add this route to authroutes.json to require logging in
        const imagesRef = collection(firestoreInstance, '/images');
        
        const storage = getStorage();
        // Create a child reference
        const imagesStorageRef = ref(storage, 'images');
        
        // all images
        Promise.all([getDocs(imagesRef)]).then(([imgSnapshot]) => {
            //setImageList([]);
            imgSnapshot.forEach(record => {
                const data = record.data();
                const newImage = {
                    file: null,
                    fileName: data.filename,
                    status: "FINISH",
                    storageRef: ref(imagesStorageRef, data.filename),
                    downloadURL: data.url,
                    description: "",
                };
                console.log("newImage ", newImage);
                //setImageList((prevState) => [...prevState, newImage]);
            });
            setLoaded(true);
        }).catch(error => {
            setLoaded(true);
            setError(error.message);
        })
        
    },[authInstance, config.saas.permissions, firestoreInstance]);

    const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
    const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
    const xLabels = [
      'Page A',
      'Page B',
      'Page C',
      'Page D',
      'Page E',
      'Page F',
      'Page G',
    ];
    
    const data = [
      { label: 'Group A', value: 400, color: '#0088FE' },
      { label: 'Group B', value: 300, color: '#00C49F' },
      { label: 'Group C', value: 300, color: '#FFBB28' },
      { label: 'Group D', value: 200, color: '#FF8042' },
    ];

    const sizing = {
      margin: { right: 5 },
      /*width: 200,*/
      height: 200,
      legend: { hidden: true },
    };
    const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);
    
    const getArcLabel = (params) => {
      const percent = params.value / TOTAL;
      return `${(percent * 100).toFixed(0)}%`;
    };
    
    return (
        <>
            {loaded?(
                <Container maxWidth="lx">
                    <SetPageTitle title="Stats" />
                    <Paper>
                        <BarChart
                           xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
                           series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
                           height={300}
                        />
                        <LineChart
                           height={300}
                           series={[
                             { data: pData, label: 'pv' },
                             { data: uData, label: 'uv' },
                           ]}
                           xAxis={[{ scaleType: 'point', data: xLabels }]}
                        />
                        <LineChart
                           height={300}
                           series={[{ data: uData, label: 'uv', area: true, showMark: false }]}
                           xAxis={[{ scaleType: 'point', data: xLabels }]}
                           sx={{
                             '.MuiLineElement-root': {
                               display: 'none',
                             },
                           }}
                        />
                        <PieChart
                           series={[
                             {
                               outerRadius: 80,
                               data,
                               arcLabel: getArcLabel,
                             },
                           ]}
                           sx={{
                             [`& .${pieArcLabelClasses.root}`]: {
                               fill: 'white',
                               fontSize: 14,
                             },
                           }}
                           {...sizing}
                        />
                    </Paper>
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Stats;
