import { AuthContext, FireactContext, SetPageTitle } from "../../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import {PageSection} from "../../template/TemplateElements";
import Footer from "../../template/Footer";
import { SubscriptionContext } from "../../lib/components/SubscriptionContext";
import { checkPermission } from "../../lib/components/utilities";

const Post = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);

    const { subscription } = useContext(SubscriptionContext);
    
    const [defaultPermissions, setDefaultPermissions] = useState([]);
    const [adminPermissions, setAdminPermissions] = useState([]);
    
    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name

        //console.log("config.saas.permissions ", config.saas.permissions);

        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            //console.log("value ", value);
            if(value.default){
                defaultPermission = permission;
                //console.log("defaultPermission ", defaultPermission);
            }
        }

        const defaultPermissions = [];
        const adminPermissions = [];
        //enableExtensionMenu(null);

        for(let permission in config.saas.permissions){
            if(config.saas.permissions[permission].default){
                defaultPermissions.push(permission);
                //console.log("defaultPermissions ", defaultPermissions);
            }
            if(config.saas.permissions[permission].admin){
                adminPermissions.push(permission);
            }
        }

        setDefaultPermissions(defaultPermissions);
        setAdminPermissions(adminPermissions);
/*
        if (checkPermission(subscription, authInstance.currentUser.uid, adminPermissions)) {
            enableExtensionMenu("groupAdmin");
        }

        if (checkPermission(subscription, authInstance.currentUser.uid, defaultPermissions)) {
            enableExtensionMenu("groupAccess");
        }
*/
        setLoaded(true);
        
    },[authInstance, config.saas.permissions, firestoreInstance]);
    
    return (
        <>
            {loaded?(
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title="OpenTech+ Posts" />
                     <Box sx={{ flexGrow: 1, p: 0, width: '100%', minHeight: '100vh'}} >
                     <Grid container spacing={0}>
                           <Grid xs={12} md={12}>
                             <PageSection>
                             <Typography component="h1" variant="h4">
                              {authUser.user  !== null &&
                                  <div>Hello {authUser.user.displayName}</div>
                              }
                              {authUser.user  === null &&
                                  <div>Welcome to OpenTech+</div>
                              }
                              {checkPermission(subscription, authInstance.currentUser.uid, adminPermissions) && 
                                <>
                                    <div>add post</div>
                                </>
                              }
                              {checkPermission(subscription, authInstance.currentUser.uid, defaultPermissions) && 
                                <>
                                    <div>read post</div>
                                </>
                              }
                             </Typography>
                             </PageSection>
                           </Grid>
                           <Grid xs={12} md={12}>
                             <PageSection>
                                posts here
                             </PageSection>
                           </Grid>
                           <Grid xs={12} md={4}>
                             <PageSection></PageSection>
                           </Grid>
                           <Grid xs={12} md={4}>
                             <PageSection></PageSection>
                           </Grid>
                           <Grid xs={12} md={4}>
                             <PageSection></PageSection>
                           </Grid>
                         </Grid>
                    </Box>
                    <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Post;
