import React, { useContext, useEffect, useState } from "react";
import { useParams, Outlet, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { Alert, Box, Container } from "@mui/material";

const NavProvider = (props) => {
    const {loader, enableExtensionMenu, ...rest} = props;
    const location = useLocation();
    
    useEffect(() => {
        //console.log("settings page props on loacation changed ", props);
        if (enableExtensionMenu) {
            enableExtensionMenu();
        }
    }, [location]);
    //}, [props.friend.id]); // Only re-subscribe if props.friend.id changes

    return (
        <Outlet loader={loader} />
    )

}

export default NavProvider;
